import React from "react";
import styles from "./FeaturesList.module.scss";
function FeaturesListItems(props) {
  return (
    <div className={styles["ecommerce-page-redkite-will-items-box"]}>
      <div className={styles["ecommerce-page-redkite-will-items-text"]}>
        <div>
          <img
            src={props.image}
            alt="check icon"
            className={styles["ecommerce-page-redkite-will-items-img"]}
          />
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: props.paragraph }}
          className={styles.textHeight}
        />
      </div>
    </div>
  );
}
export default FeaturesListItems;
