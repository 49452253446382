import React, { useState, useEffect, useContext } from "react";
import Text from "./Text";
import Fields from "./Fields";
import styles from "./CallBackForm.module.scss";
import CloseForm from "../../../img/CloseForm.svg";
import LangContext from "../../../LangContext";

function CallBackForm({
  close,
  setClose,
  setAfterSendSuccess,
  setAfterSendError,
}) {
  function handleClose() {
    setClose(true);
  }

  const [apiData, setApiData] = useState({});
  const l = useContext(LangContext);

  async function fetchData() {
    const res = await fetch(`${process.env.REACT_APP_API}menu.json?lang=${l}`);
    res.json().then((res) => setApiData(res));
  }

  useEffect(() => {
    if (!close) fetchData();
  }, [l, close]);

  return (
    <div className={close ? styles.mainBoxNone : styles.mainBox}>
      <div className={styles.formBox}>
        <div className={styles.close}>
          <img
            src={CloseForm}
            alt="redkite close form icon"
            className={styles.closeIcon}
            onClick={handleClose}
          />
        </div>
        <Text title={apiData.headline} subTitle={apiData.headlineParagraph} />
        <Fields
          contactSectionFields={apiData.contactSectionFields}
          termsText={apiData.termsText}
          toEmail={apiData.toEmail}
          send={apiData.contactSectionButton}
          setAfterSendSuccess={setAfterSendSuccess}
          setAfterSendError={setAfterSendError}
          handleClose={handleClose}
        />
      </div>
    </div>
  );
}

export default CallBackForm;
