import React, { useEffect } from "react";
import styles from "./Privacy.module.scss";

import BackgroundIsGradient from "../common/CareerImgComponent/BackgroundIsGradient/BackgroundIsGradient";
import CareerBoxforItem from "../Career/CareerBoxforItem/CareerBoxforItem";

import Title from "../common/Text/Title";
import Heading_Description from "../Terms/Heading_Description";

import useFetchPageData from "../../utils/useFetchPageData";

function Privacy() {
  const policyData = useFetchPageData("tos/privacy-policy", true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!policyData.policyContent) {
    return null;
  }

  return (
    <div>
      <Title titleStyle={styles.textStyle}>{policyData.title}</Title>
      <BackgroundIsGradient
        policyColor1={policyData.policyColor1}
        policyColor2={policyData.policyColor2}
      >
        <CareerBoxforItem boxStyle={styles.boxStyle}>
          {policyData.policyContent.map((data) => {
            return <Heading_Description {...data} />;
          })}
        </CareerBoxforItem>
      </BackgroundIsGradient>
    </div>
  );
}

export default Privacy;
