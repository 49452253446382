import React, { useEffect, useContext } from "react";
import TopIDToScroll from "../common/TopIDToScroll/";
import FirstSection from "../common/VideoSection/";
import ClientsSection from "../common/ClientsSection/";
import TestimonialsSection from "../common/TestimonialsSection/";
import WorksSection from "../common/WorksSection/";
import AboutSection from "../common/AboutSection/";
import GetInTouchSection from "../common/GetInTouchSection/";
import useFetchPageData from "../../utils/useFetchPageData";
import LangContext from "../../LangContext";
import Solutions from "../common/Solutions/Solutions";

const MainPage = ({ setClose, close }) => {
  const l = useContext(LangContext);
  const apiData = useFetchPageData("homepage", true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page">
      <TopIDToScroll />
      <FirstSection
        headline={apiData.headline}
        video={apiData.video}
        headlineButton={apiData.headlineButton}
        headlineLink={apiData.headlineLink}
        partners={apiData.partners}
        partnerIcons={apiData.partnerIcons}
        l={l}
        headlineParagraph={apiData.headlineParagraph}
      />

      <ClientsSection
        heading={apiData.clientsHeading}
        client={apiData.client}
      />

      {!!apiData.solutions && (
        <Solutions
          heading={apiData.solutionsHeading}
          text={apiData.solutionstext}
          solutions={apiData.solutions}
          button={apiData.solutionsButton}
          l={l}
        />
      )}

      <TestimonialsSection reviews={apiData.reviews} />
      {!!apiData.works && (
        <WorksSection
          heading={apiData.worksHeading}
          works={apiData.works}
          l={l}
        />
      )}
      <AboutSection
        heading={apiData.aboutHeading}
        big={apiData.aboutBig}
        small={apiData.aboutSmall}
        l={l}
      />
      <GetInTouchSection
        heading={apiData.footerHeading}
        footer={apiData.footer}
        l={l}
        setClose={setClose}
        close={close}
      />
    </div>
  );
};

export default MainPage;
