import React from "react";
import Text from "../common/Text/Text";
import styles from "./Terms.module.scss";
function Heading_Description({ heading, description }) {
  return (
    <div>
      <Text textStyle={styles.text}>{description}</Text>
      <Text textStyle={styles.text} weight="700">
        {heading}
      </Text>
    </div>
  );
}

export default Heading_Description;
