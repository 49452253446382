import React from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./MainHeader.module.scss";
import { isPathMatching } from "../../utils/isPathMatching";
import cx from "classnames";
function MainHeaderLogo({ logoWhite, logoBlack, l, lang, locale }) {
  const handleScroll = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div
      className={`${styles.mainHeaderLogoBox} 
   
    `}
    >
      <Link exact to={`/${locale}`}>
        <img
          src={isPathMatching(useLocation(), locale) ? logoWhite : logoBlack}
          alt="Redkite Logo"
          className={cx(styles.mainHeaderLogo, {
            [styles.EngMainHeaderLogo]: l === "Eng",
          })}
          onClick={handleScroll}
        />
      </Link>
    </div>
  );
}
export default MainHeaderLogo;
