import React from "react";
import styles from "./RightTextAndLeftBoxWithButtons.module.scss";
function RightText({ smallText }) {
  return (
    <div
      className={styles["ecommerce-page-right-text-box"]}
      dangerouslySetInnerHTML={{
        __html: smallText,
      }}
    />
  );
}
export default RightText;
