import React from "react";
import styles from "./LargIMGWithRightBox.module.scss";
function LargIMGWithRightSubBoxText({ heading, paragraph }) {
  return (
    <div className={styles["ecommerce-why-redkite-right-subbox-text-box"]}>
      <div
        className={styles["ecommerce-why-redkite-right-subbox-title"]}
        dangerouslySetInnerHTML={{
          __html: heading,
        }}
      />
      <div
        className={styles["ecommerce-why-redkite-right-subbox-text"]}
        dangerouslySetInnerHTML={{
          __html: paragraph,
        }}
      />
    </div>
  );
}
export default LargIMGWithRightSubBoxText;
