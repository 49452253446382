import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Slider from "react-slick";
import WorkSectionItem from "./WorkSectionItem";
import styles from "./WorkSection.module.scss";
import "./arrow.scss";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={`${className} ${styles.nextIcon}`}
      style={{ display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={`${className} ${styles.prevIcon}`}
      style={{ display: "block" }}
      onClick={onClick}
    />
  );
}

function WorksSection({ heading, works, l }) {
  const mainWorks = works.slice(0, 4);
  const restWorks = works.slice(4);

  const [isShown, setShow] = useState(false);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    arrow: false,
    autoplaySpeed: 4000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div>
      <div id="works" className={styles.idWorks}></div>

      <div className={styles["works-section-box"]}>
        <div className={styles["works-section-box-title"]}>{heading}</div>
        <div className={styles.workSectionForMobileHide}>
          <Slider {...settings}>
            {!!works &&
              works.map((works) => {
                return <WorkSectionItem {...works} l={l} />;
              })}
          </Slider>
        </div>

        <div className={styles.workSectionForMobileShow}>
          {!!mainWorks &&
            mainWorks.map((works) => {
              return <WorkSectionItem {...works} l={l} />;
            })}
          {!!restWorks &&
            isShown &&
            restWorks.map((works) => {
              return <WorkSectionItem {...works} l={l} />;
            })}
        </div>
        {works.length > 4 && (
          <div className={styles.showMoreButtonBox}>
            <input
              type="button"
              value={isShown ? "Show Less" : "EXPLORE ALL works"}
              className={styles.showMoreButton}
              onClick={() => {
                setShow(!isShown);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
export default WorksSection;
