import React from "react";
import styles from "./TestimonialsSection.module.scss";
import "./slickStyle.scss";
function TestimonialsSectionSlider({ heading, text }) {
  return (
    <div className={styles["testimonials-section-auter-and-text"]}>
      <div
        className={styles["legend-author"]}
        dangerouslySetInnerHTML={{
          __html: heading,
        }}
      />
      <div
        className={styles["legend-text"]}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
}
export default TestimonialsSectionSlider;
