import React from "react";
import LargIMGWithRightSubBox from "./LargIMGWithRightSubBox";
import styles from "./LargIMGWithRightBox.module.scss";
function LargIMGWithRightBoxRightBox({
  heading,
  paragraph,
  button,
  buttonLink,
}) {
  return (
    <div className={styles["ecommerce-why-redkite-right-box-box"]}>
      <LargIMGWithRightSubBox
        heading={heading}
        paragraph={paragraph}
        button={button}
        buttonLink={buttonLink}
      />
    </div>
  );
}
export default LargIMGWithRightBoxRightBox;
