import React, { useEffect, useContext } from "react";
import TopIDToScroll from "../common/TopIDToScroll/";
import LeftAndRightSections from "../common/LeftAndRIghtSections";
import AboutSectionBoxSecondRow from "../common/AboutSection/AboutSectionBoxSecondRow";
import Staff from "../common/Staff";
import ContactUs from "../common/ContactUs";
import useFetchPageData from "../../utils/useFetchPageData";
import styles from "./AboutStaff.module.scss";
import LangContext from "../../LangContext";
function App() {
  const locale = useContext(LangContext);
  const apiData = useFetchPageData("about", true);
  const contactData = useFetchPageData("contact", false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page">
      <TopIDToScroll />
      <LeftAndRightSections
        heading={apiData.aboutHeading}
        content={apiData.aboutContent}
        newStyle={styles.contentStyleForAboutUs}
        newStyleTitle={styles.newStyleForTitle}
        titleStyyle={styles.t}
      />
      <div className={styles.top}></div>
      <AboutSectionBoxSecondRow
        small={apiData.aboutSmall}
        newStyle={locale === "en" ? styles.textColor : styles.textColorRUS}
        forAbout={
          locale === "en" ? styles.styleForAbout : styles.styleForAboutRUS
        }
      />
      <div className={styles.bottom}></div>
      <Staff
        title={apiData.closingContentSectionHeading}
        subTitle={apiData.closingContentSectionParagraph}
        staffHeading={apiData.contentSectionHeading}
        staffProps={apiData.aboutPeople}
      />
      <ContactUs
        contactSectionHeading={contactData.contactSectionHeading}
        contactSectionFields={contactData.contactSectionFields}
        contactSectionButton={contactData.contactSectionButton}
      />
    </div>
  );
}

export default App;
