import * as types from "../types";

const setPageData = (slug, data) => {
  return (dispatch) =>
    dispatch({
      type: types.SET_PAGE_DATA,
      payload: { slug, data },
    });
};

const setLoading = (isLoading) => {
  return (dispatch) =>
    dispatch({
      type: types.SET_LOADING,
      payload: isLoading,
    });
};

const setSocialData = (data) => {
  return (dispatch) =>
    dispatch({
      type: types.SET_SOCIAL_DATA,
      payload: data,
    });
};

const setLang = (data) => {
  return (dispatch) =>
    dispatch({
      type: types.SET_LANG,
      payload: data,
    });
};

export { setPageData, setLoading, setSocialData, setLang };
