import React from "react";
import GradientNumberListLeftPart from "./GradientNumberListLeftPart";
import GradientNumberListRightPart from "./GradientNumberListRightPart";
import BlocksItems from "../Blocks/BlocksItems";
import styles from "./GradientNumbersList.module.scss";
function GradientNumberListItems({
  number,
  heading,
  firstColor,
  secondColor,
  paragraph,
  secondColumn,
}) {
  return (
    <div>
      <div className={styles["ecommerce-customer-journey-items-box"]}>
        <GradientNumberListLeftPart
          number={number}
          heading={heading}
          firstColor={firstColor}
          secondColor={secondColor}
        />
        <GradientNumberListRightPart paragraph={paragraph} />
      </div>

      {!!secondColumn && (
        <BlocksItems secondColumn={secondColumn} newStyle={styles.blocks} />
      )}
    </div>
  );
}
export default GradientNumberListItems;
