import React from "react";
import styles from "./GradientNumbersList.module.scss";
function GradientNumberListLeftPart({
  number,
  heading,
  firstColor,
  secondColor,
}) {
  return (
    <div
      className={styles["ecommerce-customer-journey-items-left-part-box"]}
      style={{
        backgroundImage: `linear-gradient( 180deg, ${firstColor},    ${secondColor} )`,
      }}
    >
      <div
        className={styles["ecommerce-customer-journey-items-left-part-number"]}
        dangerouslySetInnerHTML={{
          __html: number,
        }}
      />

      <div
        className={styles["ecommerce-customer-journey-items-left-part-text"]}
        dangerouslySetInnerHTML={{
          __html: heading,
        }}
      />
    </div>
  );
}
export default GradientNumberListLeftPart;
