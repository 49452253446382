import React from "react";
import styles from "./CallBackForm.module.scss";
function Text({ title, subTitle }) {
  return (
    <div className={styles.texts}>
      <div
        className={styles.title}
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />

      <div
        className={styles.titleTimeZone}
        dangerouslySetInnerHTML={{
          __html: subTitle,
        }}
      />
    </div>
  );
}

export default Text;
