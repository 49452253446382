import React from "react";
import styles from "./LeftAndRightSections.module.scss";
import { Link } from "react-router-dom";
function LeftAndRightSectionsMainButton({ button, buttonLink }) {
  return (
    <div className={styles["ecommerce-success-stories-button-box"]}>
      <Link to={buttonLink}>
        <input
          type="button"
          value={button}
          className={styles["ecommerce-success-stories-button-text"]}
        />
      </Link>
    </div>
  );
}
export default LeftAndRightSectionsMainButton;
