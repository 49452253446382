import React from "react";
import MainFooterFirstSubscribe from "./MainFooterFirstSubscribe";
import MainFooterFirstTouch from "./MainFooterFirstTouch";
import MainFooterFirstContact from "./MainFooterFirstContact";
import styles from "./MainFooter.module.scss";
function MainFooterFirst({
  getInTouchTitle,
  socialMediaTitle,
  subscribeTitle,
  getInTouchText,
  socialMedia,
  footerLogo,
  subscribePlaceholder,
  subscribeButton,
  l,
  termsText,
  policyText,
  copyright,
  policyLink,
  termsLink,
}) {
  return (
    <div className={styles["main-footer-first-box"]}>
      <div className={styles.forMobileBox}>
        <div>
          <img src={footerLogo} alt="white Redkite Logo" />
        </div>
      </div>

      <MainFooterFirstSubscribe
        subscribeTitle={subscribeTitle}
        subscribePlaceholder={subscribePlaceholder}
        subscribeButton={subscribeButton}
        copyright={copyright}
      />
      <MainFooterFirstTouch
        getInTouchText={getInTouchText}
        getInTouchTitle={getInTouchTitle}
      />
      <MainFooterFirstContact
        socialMedia={socialMedia}
        socialMediaTitle={socialMediaTitle}
        footerLogo={footerLogo}
        l={l}
        policyText={policyText}
        policyLink={policyLink}
        termsText={termsText}
        termsLink={termsLink}
      />
    </div>
  );
}
export default MainFooterFirst;
