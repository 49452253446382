import React from "react";
import styles from "./Text.module.scss";
import cx from "classnames";

function Text({ children, weight, textStyle }) {
  const textWeight = {
    fontWeight: `${weight}`,
  };
  
  return (
    <div
      className={cx(styles.text, textStyle)}
      style={textWeight}
      dangerouslySetInnerHTML={{
        __html: children,
      }}
    />
  );
}

export default Text;
