import React from "react";
import styles from "./LeftAndRightSections.module.scss";
function LeftAndRightSectionsSubBoxText({ heading, paragraph }) {
  return (
    <div className={styles["ecommerce-success-stories-sub-box-Text-box"]}>
      <div
        className={styles["commerce-success-stories-sub-box-title"]}
        dangerouslySetInnerHTML={{ __html: heading }}
      />
      <div
        className={styles["commerce-success-stories-sub-box-Text"]}
        dangerouslySetInnerHTML={{ __html: paragraph }}
      />
    </div>
  );
}
export default LeftAndRightSectionsSubBoxText;
