import React from "react";
import styles from "./GradientNumbersList.module.scss";
function GradientNumberListRightPart({ paragraph }) {
  return (
    <div className={styles["ecommerce-customer-journey-items-right-part-box"]}>
      <div
        className={styles["ecommerce-customer-journey-items-right-part-text"]}
        dangerouslySetInnerHTML={{
          __html: paragraph,
        }}
      />
    </div>
  );
}
export default GradientNumberListRightPart;
