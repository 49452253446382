import React from "react";
import AboutSectionBox from "./AboutSectionBox";
import styles from "./AboutSection.module.scss";
import cx from "classnames";
function AboutSection({
  heading,
  big,
  small,
  l,
  aboutStyle,
  stylesAboutId,
  firstRowTextStyle,
  newStyle,
}) {
  return (
    <div>
      <div id="about" className={cx(styles.idAbout, stylesAboutId)}></div>
      <div className={cx(styles["about-section-box"], aboutStyle)}>
        <div
          className={styles["about-section-title"]}
          dangerouslySetInnerHTML={{ __html: heading }}
        />
        <AboutSectionBox
          big={big}
          small={small}
          l={l}
          firstRowTextStyle={firstRowTextStyle}
          newStyle={newStyle}
        />
      </div>
    </div>
  );
}
export default AboutSection;
