import React from "react";
import LeftAndRightSectionsSubBoxText from "./LeftAndRightSectionsSubBoxText";
import LeftAndRightSectionsButton from "./LeftAndRightSectionsButton";
import styles from "./LeftAndRightSections.module.scss";
function LeftAndRightSectionsSubBox({
  heading,
  paragraph,
  button,
  buttonLink,
  l,
}) {
  return (
    <div className={styles["left-right-box"]}>
      <div className={styles["ecommerce-success-stories-sub-box-box"]}>
        <LeftAndRightSectionsSubBoxText
          heading={heading}
          paragraph={paragraph}
        />
        {!!button && (
          <LeftAndRightSectionsButton
            button={button}
            buttonLink={buttonLink}
            l={l}
          />
        )}
      </div>
    </div>
  );
}
export default LeftAndRightSectionsSubBox;
