import React, { useEffect, useContext } from "react";
import CoverBox from "../common/Cover/";
import ContactUs from "../common/ContactUs";
import GradientNumberList from "../common/GradientNumbersList";
import MainBoxForIMGAndBox from "../common/IMGWithRightBox";
import LeftAndRightSections from "../common/LeftAndRIghtSections";
import LargIMGWithRightBox from "../common/LargIMGWithRightBox";
import useFetchPageData from "../../utils/useFetchPageData";
import Loading from "../common/Loading";
import TopIDToScroll from "../common/TopIDToScroll/";
import LangContext from "../../LangContext";

const InternalPageLayout = ({ pageName, locale, posting }) => {
  const l = useContext(LangContext);

  const apiData = useFetchPageData(pageName, true);
  const contactUS = useFetchPageData(
    posting ? "job-application" : "contact",
    false
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageName]);

  const blockNames = Object.keys(apiData);
  return (
    <div className="page">
      <TopIDToScroll />
      <Loading />
      {blockNames.map((blockName) => {
        switch (blockName) {
          case "titleSection":
            return (
              <CoverBox
                key="titleSection"
                showContent={apiData.titleSection}
                heading={apiData.titleSectionHeading}
                paragraph={apiData.titleSectionParagraph}
                buttonLink={apiData.titleSectionButtonLink}
                buttonText={apiData.titleSectionButtonText}
                image={apiData.titleSectionImage}
                text={apiData.titleSectionText}
                titleSectionAdditionalFields={
                  apiData.titleSectionAdditionalFields
                }
              />
            );

          case "contentSection":
            return (
              <MainBoxForIMGAndBox
                key="contentSection"
                showContent={apiData.contentSection}
                mainContent={apiData.mainContent}
                heading={apiData.contentSectionHeading}
                data={apiData.contentSectionData}
                contentSectionAdditionalFields={
                  apiData.contentSectionAdditionalFields
                }
                testimoniesSectionAdditionalFields={
                  apiData.testimoniesSectionAdditionalFields
                }
              />
            );
          case "roadmapSection":
            return (
              <GradientNumberList
                key="roadmapSection"
                showContent={apiData.roadmapSection}
                heading={apiData.roadmapSectionHeading}
                data={apiData.roadmapSectionData}
                button={apiData.roadmapSectionButton}
                buttonLink={apiData.roadmapSectionButtonLink}
              />
            );

          case "closingContentSection":
            return (
              <LargIMGWithRightBox
                key="closingContentSection"
                showContent={apiData.closingContentSection}
                heading={apiData.closingContentSectionHeading}
                paragraph={apiData.closingContentSectionParagraph}
                image={apiData.closingContentSectionImage}
                button={apiData.closingContentSectionButton}
                buttonLink={apiData.closingContentSectionButtonLink}
              />
            );
          case "customerCasesSection":
            return (
              <LeftAndRightSections
                key="customerCasesSection"
                showContent={apiData.customerCasesSection}
                content={apiData.customerCasesSectionContent}
                button={apiData.customerCasesSectionButton}
                heading={apiData.customerCasesSectionHeading}
                buttonLink={apiData.customerCasesSectionLink}
                l={l}
              />
            );

          case "contactSection":
            return (
              <ContactUs
                key="contactSection"
                showContent={apiData.contactSection}
                contactSectionHeading={contactUS.contactSectionHeading}
                contactSectionFields={contactUS.contactSectionFields}
                contactSectionButton={contactUS.contactSectionButton}
                toEmail={contactUS.toEmail}
                locale={locale}
                posting={posting}
              />
            );

          default:
            return null;
        }
      })}
    </div>
  );
};

export default InternalPageLayout;
