import React from "react";
import { useTranslation } from "react-i18next";

const ErrorWraaper = ({ type }) => {
  const { t } = useTranslation();
  const message = t("errorWrapper.errorText");
  const heightBasedStyle = type === "main" ? "errorElementHeight" : "";
  return <div className={`errorBoundary ${heightBasedStyle}`}>{message}</div>;
};

export default ErrorWraaper;
