import React from "react";
import styles from "./RightTextAndLeftBoxWithButtons.module.scss";

import { NavHashLink } from "react-router-hash-link";
function BoxWithButtons({
  bigText,
  firstButtonText,
  secondButtonText,
  firstButtonLink,
  secondButtonLink,
}) {
  return (
    <div className={styles["ecommerce-page-box-with-buttons-box"]}>
      <div className={styles["ecommerce-page-box-with-buttons-subbox"]}>
        <div
          className={styles["ecommerce-page-box-text"]}
          dangerouslySetInnerHTML={{
            __html: bigText,
          }}
        />

        <div className={styles["ecommerce-page-buttons-box"]}>
        {firstButtonLink.length > 0 &&
          <NavHashLink to={firstButtonLink}>
            <button
              className={styles["ecommerce-page-buttons"]}
              dangerouslySetInnerHTML={{
                __html: firstButtonText,
              }}
            />
          </NavHashLink>
        }

        {secondButtonLink.length > 0 &&
          <NavHashLink to={secondButtonLink}>
            <button
              className={styles["ecommerce-page-buttons"]}
              dangerouslySetInnerHTML={{
                __html: secondButtonText,
              }}
            />
          </NavHashLink>
        }
          <div id="innersolution" />
        </div>
      </div>
    </div>
  );
}
export default BoxWithButtons;
