import React from "react";
import StaffItems from "./StaffItems";
import styles from "./Staff.module.scss";
function StaffBox({ staffHeading, staffProps }) {
  return (
    <div className={styles.staffBox}>
      <div
        className={styles.staffBoxTitle}
        dangerouslySetInnerHTML={{ __html: staffHeading }}
      />
      <div className={styles.staffBoxForItems}>
        {!!staffProps &&
          staffProps.map((data) => {
            return <StaffItems key={data.image} {...data} />;
          })}
      </div>
    </div>
  );
}

export default StaffBox;
