import React from "react";
import LeftAndRightSectionsSubBox from "./LeftAndRightSectionsSubBox";
import styles from "./LeftAndRightSections.module.scss";
function LeftAndRightSectionsBox({
  image,
  paragraph,
  heading,
  button,
  buttonLink,
  l,
}) {
  const mystyle = {
    backgroundImage: `url(${image})`,
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  return (
    <div
      className={styles["ecommerce-success-stories-box-box"]}
      style={mystyle}
    >
      <div className={styles["ecommerce-success-stories-box-info-box"]}>
        <LeftAndRightSectionsSubBox
          paragraph={paragraph}
          heading={heading}
          button={button}
          buttonLink={buttonLink}
          l={l}
        />
      </div>
    </div>
  );
}
export default LeftAndRightSectionsBox;
