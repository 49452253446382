import React from "react";
import LeftAndRightSectionsBox from "./LeftAndRightSectionsBox";
import LeftAndRightSectionsMainButton from "./LeftAndRightSectionsMainButton";
import styles from "./LeftAndRightSections.module.scss";
import cx from "classnames";
function LeftAndRightSections({
  content,
  button,
  heading,
  buttonLink,
  l,
  newStyle,
  titleStyyle,
}) {
  const mainContent = content?.length ? content.slice(0, 2) : [];

  return (
    <div className={cx(styles["ecommerce-success-stories-box"], newStyle)}>
      <div id="innerwords" />
      {heading && (
        <div
          className={cx(styles["ecommerce-success-stories-title"], titleStyyle)}
          dangerouslySetInnerHTML={{
            __html: heading,
          }}
        />
      )}
      <div className={styles["test"]}>
        {!!mainContent &&
          mainContent.map((data) => {
            return (
              <LeftAndRightSectionsBox key={data.heading} {...data} l={l} />
            );
          })}
      </div>

      {!!button && (
        <LeftAndRightSectionsMainButton
          button={button}
          buttonLink={buttonLink}
        />
      )}
      <div className={styles.emailUs} id="emailus"></div>
    </div>
  );
}
export default LeftAndRightSections;
