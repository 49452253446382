import React, { useContext } from "react";
import styles from "./LargIMGWithRightBox.module.scss";
import { NavHashLink } from "react-router-hash-link";
import LangContext from "../../../LangContext";

function LargIMGWithRightSubBoxButton({ button, buttonLink }) {
  const locale = useContext(LangContext);
  return (
    <div className={styles["ecommerce-why-redkite-right-subbox-button-box"]}>
      {!!buttonLink && (
      <NavHashLink to={`/${locale}${buttonLink}`}>
        <input
          type="button"
          value={button}
          className={styles["ecommerce-why-redkite-right-subbox-button"]}
        />
      </NavHashLink>
      )}
      <div id="innerwords" />
    </div>
  );
}
export default LargIMGWithRightSubBoxButton;
