import React from "react";
import AboutSectionBoxFirstRow from "./AboutSectionBoxFirstRow";
import AboutSectionBoxSecondRow from "./AboutSectionBoxSecondRow";
import AboutSectionButton from "./AboutSectionButton";
import styles from "./AboutSection.module.scss";

function AboutSectionBox({ big, small, l, firstRowTextStyle, newStyle }) {
  return (
    <div className={styles["about-section-box-box"]}>
      <AboutSectionBoxFirstRow
        big={big}
        firstRowTextStyle={firstRowTextStyle}
      />

      <AboutSectionBoxSecondRow
        small={small}
        l={l}
        newStyle={newStyle}
        forAbout={styles.forAbout}
      />
      <AboutSectionButton />
    </div>
  );
}
export default AboutSectionBox;
