import React from "react";
import GetInTouchSectionBox from "./GetInTouchSectionBox";
import styles from "./GetInTouchSection.module.scss";
function GetInTouchSection({ heading, footer, l, setClose }) {
  return (
    <div>
      <div className={styles.idContact}></div>
      <div className={styles["get-in-touch-section-box"]} id="contact">
        <div
          className={styles["get-in-touch-section-title"]}
          dangerouslySetInnerHTML={{
            __html: heading,
          }}
        />
        <GetInTouchSectionBox footer={footer} l={l} setClose={setClose} />
      </div>
    </div>
  );
}
export default GetInTouchSection;
