import React from "react";
import BoxWithButtons from "./BoxWithButtons";
import RightText from "./RightText";
import Blocks from "../Blocks";
import styles from "./RightTextAndLeftBoxWithButtons.module.scss";
function BoxAndText({
  bigText,
  smallText,
  firstButtonText,
  secondButtonText,
  firstButtonLink,
  secondButtonLink,
  titleSectionAdditionalFields,
}) {
  return (
    <div className={styles["ecommerce-page-box-for-box-and-text"]}>
      <BoxWithButtons
        bigText={bigText}
        firstButtonText={firstButtonText}
        secondButtonText={secondButtonText}
        firstButtonLink={firstButtonLink}
        secondButtonLink={secondButtonLink}
      />
      <RightText smallText={smallText} />

      {!!titleSectionAdditionalFields && (
        <Blocks
          titleSectionAdditionalFields={titleSectionAdditionalFields}
          newStyle={styles.block}
        />
      )}
    </div>
  );
}
export default BoxAndText;
