import React from "react";
import styles from "./Title.module.scss";
import cx from "classnames";

function Title({ children, titleStyle }) {
  return (
    <div
      className={cx(styles.title, titleStyle)}
      dangerouslySetInnerHTML={{
        __html: children,
      }}
    />
  );
}

export default Title;
