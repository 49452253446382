import React from "react";
import FeaturesListItemsBox from "./FeaturesListItemsBox";
import styles from "./FeaturesList.module.scss";
function FeaturesList({ heading, data }) {
  return (
    <div>
      <div className={styles["ecommerce-page-redkite-will-box"]}>
        <div
          className={styles["ecommerce-page-redkite-will-box-title"]}
          dangerouslySetInnerHTML={{
            __html: heading,
          }}
        />

        <div className={styles.boxWrapper}>
          <FeaturesListItemsBox data={data} />
        </div>
      </div>
    </div>
  );
}
export default FeaturesList;
