import React from "react";
import Viber from "../../../img/viber.svg";
import Skype from "../../../img/skype.svg";
import Whatsapp from "../../../img/Whatsapp.svg";
import styles from "./GetInTouchSection.module.scss";
function SocialIcons() {
  return (
    <div className={styles.socialIconsWrapper}>
      <a href="skype:melpomenada?call">
        <img src={Skype} alt="skype icon" />
      </a>
      <a href="viber://chat?number=+37433027007">
        <img src={Viber} alt="Viber icon" />
      </a>
      <a href="https://wa.me/37433027007">
        <img src={Whatsapp} alt="Whatsapp icon" />
      </a>
    </div>
  );
}

export default SocialIcons;
