import React from "react";
import styles from "./MainHeader.module.scss";
import { useLocation } from "react-router-dom";
import { NavHashLink as NavLink } from "react-router-hash-link";
import cx from "classnames";

import { isPathMatching } from "../../utils/isPathMatching";

function MainHeaderItem({ menuItem, itemLink, l, lang, locale }) {
  return (
    <div
      className={cx(styles["main-header-item"], {
        [styles.EngMainHeaderItem]: l === "Eng",
      })}
    >
      <NavLink
        exact
        activeClassName="active"
        to={`/${locale}${itemLink}`}
        className={styles["inactive"]}
        style={{
          color: isPathMatching(useLocation(), locale) ? "white" : "black",
        }}
      >
        {menuItem}
      </NavLink>
    </div>
  );
}
export default MainHeaderItem;
