import React from "react";
import ClienstsSectionClients from "./ClientsSectionClients";
import styles from "./ClientSection.module.scss";

function ClientsSection({ heading, client }) {
  return (
    <div>
      <div className={styles.idClients}></div>
      <div className={styles["clients-section-box"]} id="clients">
        <div
          className={styles["clients-section-text"]}
          dangerouslySetInnerHTML={{
            __html: heading,
          }}
        />
        <div className={styles["clients-section-clients-box"]}>
          {!!client &&
            client.map((data) => {
              return <ClienstsSectionClients key={data.image} {...data} />;
            })}
        </div>
      </div>
      <div id="solutions" className={styles.IDsolutions}></div>
    </div>
  );
}
export default ClientsSection;
