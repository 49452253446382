import React, { useEffect } from "react";
import styles from "./Career.module.scss";
import { useMediaQuery } from "react-responsive";

import Title from "../common/Text/Title/Title";
import CareerImgComponent from "../common/CareerImgComponent/CareerImgComponent";
import CareerBoxforItem from "./CareerBoxforItem/CareerBoxforItem";
import CareerItem from "./CareerItem/CareerItem";

import CareerImgComponentForMobile from "../common/CareerImgComponent/CareerImgComponentForMobile/CareerImgComponentForMobile";
import useFetchPageData from "../../utils/useFetchPageData";

function Career() {
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  const apiData = useFetchPageData("career", true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const data = apiData.jobPostingLink;

  return (
    <div>
      <Title titleStyle={styles.textStyle}>
        {apiData.careerSectionHeading}
      </Title>
      {isMobile && (
        <CareerImgComponentForMobile bgPicture={apiData.careerSectionImage} />
      )}
      <CareerImgComponent
        backgroundStyle={styles.backgroundStyle}
        bgPicture={apiData.careerSectionImage}
      >
        {!!data?.length && (
          <CareerBoxforItem boxStyle={styles.boxStyle}>
            {data.map((data) => (
              <CareerItem {...data} />
            ))}
          </CareerBoxforItem>
        )}
      </CareerImgComponent>
    </div>
  );
}

export default Career;
