import React, { useContext } from "react";
import styles from "./MobileMenu.module.scss";
import CloseMobileMenu from "../../../img/CloseMobileMenu.svg";
import MobileMenuItemsBox from "./MobileMenuItemsBox";
import { useLocation, Link } from "react-router-dom";
import Facebook from "../../../img/Facebook.svg";
import Instagram from "../../../img/Instagram.svg";
import LogoWhite from "../../../img/LogoWhite.svg";
import { useSelector } from "react-redux";
import { isPathMatching } from "../../../utils/isPathMatching";
import LangContext from "../../../LangContext";
function MobileMenu({
  show,
  showMobileMenu,
  navigation,
  setLang,
  lang,
  l,
  callNumber,
  callBackText,
  callBackLink,
  setClose,
  close,
}) {
  const menuWrapperClass = show ? styles.menuWrapperClass : "";
  // const socialData = useSelector((state) => state.socialData);

  const locale = useContext(LangContext);
  return (
    <div className={styles.MobileMenuBox}>
      <div
        className={`${styles.MobileMenu} ${menuWrapperClass}`}
        style={{
          backgroundImage: isPathMatching(useLocation(), locale)
            ? `linear-gradient( 180deg, black ,    #46386B )`
            : `linear-gradient( 180deg, white ,   white )`,
        }}
      >
        <MobileMenuItemsBox
          navigation={navigation}
          showMobileMenu={showMobileMenu}
          callNumber={callNumber}
          callBackText={callBackText}
          callBackLink={callBackLink}
          setClose={setClose}
          close={close}
          locale={locale}
        />
        <div>
          <img
            src={CloseMobileMenu}
            alt="close icon"
            onClick={() => {
              showMobileMenu(false);
            }}
            className={styles.closeIcon}
          />
        </div>
      </div>
    </div>
  );
}

export default React.forwardRef(MobileMenu);
