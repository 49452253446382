import React from "react";
import cx from "classnames";
import styles from "./Background.module.scss";

const Background = ({ backgroundsArr }) => {
  return (
    !!backgroundsArr.length && (
      <div className={styles.wrapper}>
        {backgroundsArr.map((element, index) => (
          <img
            key={index}
            src={element}
            className={cx(styles.animation)}
            alt=""
          />
        ))}
      </div>
    )
  );
};
export default Background;
