import React, { useState } from "react";
import axios from "axios";
import styles from "./MainFooter.module.scss";
import Loading from "../common/LoadingCircle";
import PopUp from "../common/PopUp/PopUp";
import Success from "../../img/success.svg";
import Error from "../../img/error.svg";
import { useTranslation } from "react-i18next";
const date = new Date();
const year = date.getFullYear();
function MainFooterFirstSubscribe({
  subscribeTitle,
  subscribePlaceholder,
  subscribeButton,
  copyright,
}) {
  const [email, setEmail] = useState("");
  const [formLoading, setFormLoading] = useState(false);
  const [afterSendSuccess, setAfterSendSuccess] = useState();
  const [afterSendError, setAfterSendError] = useState("");
  const { t } = useTranslation();
  const submit = (e) => {
    e.preventDefault();
    setFormLoading(true);
    const formData = new FormData();
    formData.set("action", "mailchimp-subscribe/audience/subscribe");
    formData.set("email", email);
    axios({
      url: process.env.REACT_APP_API,
      data: formData,
      method: "POST",
    })
      .then(() =>
        setAfterSendSuccess(
          <PopUp
            img={Success}
            text={t("success.subscribe")}
            newStyle={styles.feedback}
          />
        )
      )
      .catch(() =>
        setAfterSendError(
          <PopUp
            img={Error}
            text={t("warning.subscribe")}
            newStyle={styles.feedback}
          />
        )
      )
      .finally(() => setFormLoading(false))
      .finally(() => setEmail(""));
  };

  return (
    <div className={styles["main-footer-first-subscribe-box"]}>
      <div className={styles["main-footer-first-text"]}></div>

      <form onSubmit={submit}>
        <div className={styles["main-footer-div"]}>
          
        </div>
        <div className={styles.message}>
          <div className={styles.afterSendSuccess}> {afterSendSuccess}</div>
          <div className={styles.afterSendError}> {afterSendError}</div>
        </div>
      </form>
      <div className={styles.gic}></div>
      <div className={styles.d}>
        © 2014 - {year} {copyright}
      </div>
    </div>
  );
}
export default MainFooterFirstSubscribe;
