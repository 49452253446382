import React from "react";
import styles from "./GetInTouchSection.module.scss";
import GetInTouchSectionBoxItems from "./GetInTouchSectionBoxItems";
function GetInTouchSectionBox({ footer, l, setClose }) {
  return (
    <div className={styles["get-in-touch-section-box-box"]}>
      {!!footer &&
        footer.map((footer, index) => {
          return (
            <GetInTouchSectionBoxItems
              key={footer.heading}
              sectionKey={index}
              l={l}
              setClose={setClose}
              {...footer}
            />
          );
        })}
    </div>
  );
}
export default GetInTouchSectionBox;
