import React from "react";
import styles from "./CareerImgComponent.module.scss";
import cx from "classnames";
import { useMediaQuery } from "react-responsive";
import CareerBg from "../../../img/CareerBg.png";
function CareerImgComponent({ children, backgroundStyle, bgPicture }) {
  const mystyle = {
    backgroundImage: `url(${bgPicture})`,
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  const mystyle1 = {
    backgroundImage: "none",
  };
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  return (
    <div style={isMobile ? mystyle1 : mystyle} className={cx(backgroundStyle)}>
      {children}
    </div>
  );
}

export default CareerImgComponent;
