import React from "react";
import AboutSectionFirstRowItems from "./AboutSectionBoxFirstRowItems";
import styles from "./AboutSection.module.scss";
function AboutSectionBoxFirstRow({ big, firstRowTextStyle }) {
  return (
    <div className={styles["about-section-first-row-box"]}>
      {!!big &&
        big.map((data) => {
          return (
            <AboutSectionFirstRowItems
              key={data.image}
              {...data}
              picStyle="pic2"
              firstRowTextStyle={firstRowTextStyle}
            />
          );
        })}
    </div>
  );
}
export default AboutSectionBoxFirstRow;
