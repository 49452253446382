import React from "react";
import styles from "./BackgroundIsGradient.module.scss";
function BackgroundIsGradient({ children, policyColor1, policyColor2 }) {
  const background = {
    background: `linear-gradient( 180deg, ${policyColor2},    ${policyColor1} )`,
  };
  return (
    <div style={background} className={styles.boxStyle}>
      {children}
    </div>
  );
}

export default BackgroundIsGradient;
