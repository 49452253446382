import React from "react";
import GradientNumberList from "./GradientNumberList";
function GradientMain({ button, buttonLink, heading, data, showContent }) {
  return (
    <div>
      {!!showContent && (
        <GradientNumberList
          data={data}
          button={button}
          buttonLink={buttonLink}
          heading={heading}
        />
      )}
    </div>
  );
}
export default GradientMain;
