import React from "react";
import styles from "./Staff.module.scss";

function StaffItems(props) {
  return (
    <div className={styles.staffItemBox}>
      <div className={styles.toAlign}>
        <div className={styles.picture}>
          <img
            src={props.image}
            alt="redkite staff"
            className={styles.picProp}
          />
        </div>
        <a href={props.personalLink} target="_blank" className={styles.link}>
          <div
            className={styles.name}
            dangerouslySetInnerHTML={{
              __html: props.heading,
            }}
          />
          <div
            className={styles.position}
            dangerouslySetInnerHTML={{
              __html: props.description,
            }}
          />
        </a>
      </div>
    </div>
  );
}

export default StaffItems;
