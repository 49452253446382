import React, { useContext } from "react";
import styles from "./NotFound.module.scss";
import ErrorPic from "../../../img/404error.svg";
import Birds from "../../../img/bird.svg";
import { Link } from "react-router-dom";
import cx from "classnames";
import LangContext from "../../../LangContext";
import { useTranslation } from "react-i18next";
const NotFound = () => {
  const locale = useContext(LangContext);
  const { t } = useTranslation();
  let text = t("notFound404.notFound404Text");
  return (
    <div className={cx(styles.errorElementHeight, styles.errorBoundary)}>
      <div className={styles.imgWrapper}>
        <img src={ErrorPic} alt="404 error" className={styles.errorPic} />
        <img src={Birds} alt="404 error bird" className={styles.bird} />
      </div>
      <div className={styles.errorText}>{text}</div>
      <Link to={`/${locale}`} className={styles.linkColor}>
        <div className={styles["get-in-touch-section-box-items-button-box"]}>
          <input
            type="button"
            value={t("notFound404.buttonText")}
            className={styles["get-in-touch-section-box-items-button"]}
          />
        </div>
      </Link>
    </div>
  );
};

export default NotFound;
