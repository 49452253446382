import React from "react";

import styles from "./IMGWithRightBox.module.scss";
function IMGBox({ leftImage }) {
  return (
    <div>
      <img
        src={leftImage}
        alt="Redkite section img"
        className={styles["ecommerce-second-box-img"]}
      />
    </div>
  );
}
export default IMGBox;
