import React from "react";

import styles from "./MainFooter.module.scss";
function MainFooterSocialIcons({ image, socialMediaLink }) {
  return (
    <div>
      <a href={socialMediaLink} target="_blank">
        <img src={image} alt="Social Icon" className={styles.socialIcons} />
      </a>
    </div>
  );
}
export default MainFooterSocialIcons;
