import React from "react";
import styles from "./GradientNumbersList.module.scss";

function GradientNumberListButtons({ button, buttonLink }) {
  return (
    <div className={styles["ecommerce-customer-journey-button-box"]}>
      <a href={buttonLink} download target="_blank" rel="noopener noreferrer">
        <input
          type="button"
          value={button}
          className={styles["ecommerce-customer-journey-button-content"]}
        />
      </a>
    </div>
  );
}
export default GradientNumberListButtons;
