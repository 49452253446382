import React from "react";
import GradientNumberListItemsBox from "./GradientNumberListItemsBox";
import GradientNumberListButtons from "./GradientNumberListButtons";
import styles from "./GradientNumbersList.module.scss";
function GradientNumberList({ button, buttonLink, heading, data }) {
  return (
    <div className={styles["ecommerce-customer-journey-box"]}>
      <div id="footer" className={styles.linkID} />
      <div
        className={styles["ecommerce-customer-journey-box-title"]}
        dangerouslySetInnerHTML={{
          __html: heading,
        }}
      ></div>
      <GradientNumberListItemsBox data={data} />
      {!!button && (
        <GradientNumberListButtons button={button} buttonLink={buttonLink} />
      )}
      <div className={styles.emkaWords} id="emkawords"></div>
    </div>
  );
}
export default GradientNumberList;
