import { combineReducers } from "redux";

import pageDataReducer from "./reducers/pageDataReducer";
import loadingReducer from "./reducers/loadingReducer";
import socialDataReducer from "./reducers/socialDataReducer";
import langReducer from "./reducers/langReducer";

const rootReducer = combineReducers({
  pageData: pageDataReducer,
  loading: loadingReducer,
  socialData: socialDataReducer,
  langData: langReducer,
});

export default rootReducer;
