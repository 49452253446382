import React, { useContext } from "react";
import LangContext from "../../../LangContext";
import { useLocation } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import styles from "./MobileMenu.module.scss";
import { isPathMatching } from "../../../utils/isPathMatching";
function MobileMenuItems({ itemLink, menuItem, showMobileMenu, lang }) {
  const locate = useContext(LangContext);
  return (
    <div className={styles["main-header-item"]}>
      <NavHashLink
        exact
        activeClassName="active"
        to={`/${locate}${itemLink}`}
        className={styles["inactive"]}
        style={{
          color: isPathMatching(useLocation(), locate) ? "white" : "black",
        }}
        onClick={() => {
          showMobileMenu(false);
        }}
      >
        {menuItem}
      </NavHashLink>
    </div>
  );
}
export default MobileMenuItems;
