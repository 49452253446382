import React, { useEffect } from "react";
import styles from "./Terms.module.scss";
import BackgroundIsGradient from "../common/CareerImgComponent/BackgroundIsGradient/BackgroundIsGradient";
import CareerBoxforItem from "../Career/CareerBoxforItem/CareerBoxforItem";

import Title from "../common/Text/Title";
import Heading_Description from "./Heading_Description";

import useFetchPageData from "../../utils/useFetchPageData";
function Terms(props) {
  const termsData = useFetchPageData("tos/terms-of-use", true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  if (!termsData.policyContent) {
    return null;
  }

  return (
    <div>
      <Title titleStyle={styles.textStyle}>{termsData.title}</Title>
      <BackgroundIsGradient
        policyColor1={termsData.policyColor1}
        policyColor2={termsData.policyColor2}
      >
        <CareerBoxforItem boxStyle={styles.boxStyle}>
          {termsData.policyContent.map((data) => {
            return <Heading_Description {...data} />;
          })}
        </CareerBoxforItem>
      </BackgroundIsGradient>
    </div>
  );
}

export default Terms;
