import React, { useContext } from "react";
import { Link } from "react-router-dom";
import LangContext from "../../../../LangContext";
import cx from "classnames";
import styles from "./Item.module.scss";

const Item = ({
  description,
  heading,
  image,
  solutionLink,
  sectionActive,
  onMouseOver,
  active,
  l,
}) => {
  const locale = useContext(LangContext);
  return (
    <Link
      to={`${locale}/${solutionLink}`}
      className={cx(styles.link, { sectionActive, active })}
      onMouseOver={onMouseOver}
    >
      <img src={image} alt={description} />

      <div className={styles.p}>
        <div
          dangerouslySetInnerHTML={{ __html: heading }}
          className={styles.span}
        />
      </div>
    </Link>
  );
};
export default Item;
