import React from "react";
import SocialIcons from "./SocialIcons";
import ContactSocialIcons from "./ContactSocialIcons";
import styles from "./GetInTouchSection.module.scss";
import cx from "classnames";
function GetInTouchSectionBoxItems({
  image,
  heading,
  text,
  info,
  button,
  buttonLink,
  firstcolor,
  secondcolor,
  sectionKey,
  l,
  setClose,
  close,
}) {
  function handleClose() {
    setClose(false);
  }
  const myFun = () => {
    if (sectionKey === 0) {
      return (
        <div className={styles["get-in-touch-section-box-items-info"]}>
          
        </div>
      );
    } else {
      return (
        <div
          className={styles["get-in-touch-section-box-items-info"]}
          dangerouslySetInnerHTML={{ __html: info }}
        />
      );
    }
  };

  return (
    <div
      style={{
        backgroundImage: `linear-gradient( 270deg, ${firstcolor},    ${secondcolor} )`,
      }}
      className={styles["getintouchBG"]}
    >
      <div className={styles["getintouchIcons"]}>
        <img
          src={image}
          alt="get in touch icon"
          className={styles["get-in-touch-section-box-items-icon"]}
        />
      </div>
      <div
        className={styles["get-in-touch-section-box-items-title"]}
        dangerouslySetInnerHTML={{ __html: heading }}
      />

      <div
        className={cx(styles["get-in-touch-section-box-items-text"], {
          [styles["ENG-get-in-touch-section-box-items-text"]]: l === "Eng",
        })}
        dangerouslySetInnerHTML={{ __html: text }}
      />

      {myFun()}

      {sectionKey === 0 ? null : null}
      {sectionKey === 2 ? <ContactSocialIcons /> : null}
      {sectionKey !== 0 ? (
        <div className={styles["get-in-touch-section-box-items-button-box"]}>
          <a href={buttonLink} target="_blank" rel="noopener noreferrer">
            <input
              type="button"
              value={button}
              className={styles["get-in-touch-section-box-items-button"]}
            />
          </a>
        </div>
      ) : (
        <div className={styles["get-in-touch-section-box-items-button-box"]}>
          <input
            type="button"
            value={button}
            className={styles["get-in-touch-section-box-items-button"]}
            onClick={handleClose}
          />
        </div>
      )}
    </div>
  );
}
export default GetInTouchSectionBoxItems;
