import React, { useState, useContext } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import styles from "./ContactUs.module.scss";
import cx from "classnames";
import LangContext from "../../../LangContext";
import InfoIcon from "../../../img/info-icon.svg";
import CloseIcon from "../../../img/close.jpg";
import LoadingCircle from "../../common/LoadingCircle";
import PopUp from "../PopUp/PopUp";
import Success from "../../../img/success.svg";
import Error from "../../../img/error.svg";
import IconChecked from "../../../img/IconChecked.svg";
import IconNonChecked from "../../../img/IconNonChecked.svg";

const maxAllowedSize = 2;

const ContactUs = ({
  posting,
  locale,
  contactSectionFields,
  contactSectionHeading,
  contactSectionButton,
  toEmail,
}) => {
  const history = useHistory();
  const l = useContext(LangContext);
  const isJobTitle = posting;

  const [value, setValue] = useState({});
  const [formLoading, setFormLoading] = useState(false);
  const [nda, setNda] = useState("");
  const [files, setFiles] = useState([]);
  const [fileError, setFileError] = useState("");
  const [afterSendSuccess, setAfterSendSuccess] = useState("");
  const [afterSendError, setAfterSendError] = useState("");

  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const handleCloseAction = () => {
    history.push(`/${l}`);
  };

  const handelSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.set("action", "contact-form/send");
    formData.set("subject", "Contact us");
    formData.set("toEmail", toEmail);
    formData.set("message[First Name]", value.firstName);
    formData.append("message[Last Name]", value.lastName);
    formData.append("message[Phone]", value.contactNumber);
    formData.set("fromEmail", value.email);
    if (posting) formData.append("message[Vacancy]", posting);
    if (value.companyName)
      formData.append("message[Company]", value.companyName);
    if (value.message) formData.append("message[Body]", value.message);
    if (value.profile) formData.append("message[Profile]", value.profile);
    if (nda) formData.append("message[nda]", nda);
    if (files.length)
      files.forEach((file) => {
        formData.append("attachment[]", file);
      });

    setFormLoading(true);    
    
    axios({
      url: process.env.REACT_APP_API,
      data: formData,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(() => {
        setAfterSendSuccess(
          <PopUp
            img={Success}
            text={
              locale === "en"
                ? "Thank you. \nWe will contact you shortly."
                : "Спасибо!\n Мы свяжемся с Вами в скором времени."
            }
            newStyle={styles.feedback}
            handleCloseAction={handleCloseAction}
          />
        );

        document.getElementById("resetContactUsForm").click();
        setNda("");
        setFiles([]);
        setValue({});
      })
      .catch(() => {
        setAfterSendError(
          <PopUp
            img={Error}
            text={
              locale === "en"
                ? "Something went wrong!.\nPlease try again later."
                : "Что-то пошло не так! \nПожалуйста, повторите попытку позже"
            }
            newStyle={styles.feedback}
          />
        );
      })
      .finally(() => setFormLoading(false));
  };

  const attachFile = (e) => {
    const allowedExtensions =
      /(\.jpg|\.jpeg|\.png|\.gif|\.xlsx|\.xls|\.doc|\.docx|\.pdf)$/i;
    const newFiles = e.target.files;
    const filesArray = [...files];
    const fileNames = filesArray.map((file) => file.name);

    Array.from(newFiles).forEach((el) => {
      if (el.size / 1024 / 1024 > maxAllowedSize) {
        setFileError("file size exceeded 2 MB.");
      } else if (fileNames.includes(el.name)) {
        setFileError(`file with the name "${el.name}" already exists.`);
      } else if (!el.name.match(allowedExtensions)) {
        setFileError(`unsopported file type ${el.type}`);
      } else {
        filesArray.push(el);
      }
    });

    setFiles(filesArray);
  };

  if (!contactSectionFields || !contactSectionHeading) {
    return null;
  }

  return (
    <div className={styles["ecommerce-contact-us-box"]}>
      <div className={styles.emailUs} id="emailus"></div>
      <div className={styles["ecommerce-contact-us-title"]}>
        {posting ? `Apply for ${posting} position` : contactSectionHeading}
      </div>
      <form
        onSubmit={handelSubmit}
        className={cx(styles.form, isJobTitle ? 'job_application': 'contact_form' )}
        onChange={() => {
          setAfterSendSuccess("");
          setAfterSendError("");
        }}
      >
        <div className={styles["ecommerce-contact-us-form-structure"]}>
          <div>
            <div>
              <div
                className={styles["ecommerce-contact-us-form-structure-name"]}
              >
                <div>
                  <div>
                    <label htmlFor="firstName">
                      {`${contactSectionFields[0]?.label} `}
                      {contactSectionFields[0]?.required && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                    </label>
                    <br />
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      onChange={handleChange}
                      className={styles["form-name"]}
                      required={contactSectionFields[0]?.required}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <label htmlFor="lastName">
                      {`${contactSectionFields[1]?.label} `}
                      {contactSectionFields[1]?.required && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                    </label>
                    <br />
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      onChange={handleChange}
                      className={styles["form-name"]}
                      required={contactSectionFields[1]?.required}
                    />
                  </div>
                </div>
              </div>

              <div
                className={
                  styles["ecommerce-contact-us-form-contact-and-email"]
                }
              >
                <div>
                  <div>
                    <label htmlFor="contactNumber">
                      {`${contactSectionFields[2]?.label} `}
                      {contactSectionFields[2]?.required && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                    </label>
                    <br />
                    <input
                      type="number"
                      name="contactNumber"
                      id="contactNumber"
                      pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
                      title="Enter valid number for example (123) 456-7890
(123)456-7890
123-456-7890
123.456.7890
1234567890
+31636363634
075-63546725"
                      onChange={handleChange}
                      className={styles["form-number-and-email"]}
                      required={contactSectionFields[2]?.required}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <label htmlFor="email">
                      {`${contactSectionFields[3]?.label}: `}
                      {contactSectionFields[3]?.required && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                    </label>
                    <br />
                    <input
                      type="email"
                      name="email"
                      id="email"
                      onChange={handleChange}
                      className={styles["form-number-and-email"]}
                      required={contactSectionFields[3]?.required}
                    />
                  </div>
                </div>
              </div>
              <div className={styles["ecommerce-contact-us-form-company-name"]}>
                {!isJobTitle && (
                  <div>
                    <label htmlFor="companyName">
                      {`${contactSectionFields[4]?.label} `}
                      {contactSectionFields[4]?.required && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                    </label>
                    <br />
                    <input
                      type="text"
                      name="companyName"
                      id="companyName"
                      onChange={handleChange}
                      className={styles["form-company-name"]}
                      required={contactSectionFields[4]?.required}
                    />
                  </div>
                )}
                {isJobTitle && (
                  <div>
                    <label htmlFor="Profile">
                      {`${contactSectionFields[4]?.label} `}
                      {contactSectionFields[4]?.required && (
                        <span style={{ color: "red" }}>*</span>
                      )}
                    </label>
                    <br />
                    <input
                      type="text"
                      name="profile"
                      id="Profile"
                      onChange={handleChange}
                      className={styles["form-company-name"]}
                      required={contactSectionFields[4]?.required}
                    />
                  </div>
                )}
              </div>
            </div>
            <div
              className={cx(styles["ecommerce-contact-us-form-message"], {
                [styles.gridColumnOne]: isJobTitle,
              })}
            >
              <div>
                <label htmlFor="message">
                  {contactSectionFields[5]?.label}
                  {contactSectionFields[5]?.required && (
                    <span style={{ color: "red" }}>*</span>
                  )}
                </label>
                <br />
                <textarea
                  name="message"
                  id="message"
                  onChange={handleChange}
                  className={styles["form-message"]}
                  required={contactSectionFields[5]?.required}
                />
              </div>
            </div>
          </div>
          <div
            className={cx(styles["ecommerce-contact-us-form-NDA-and-atach"], {
              [styles.leftMargin]: isJobTitle,
            })}
          >
            {!isJobTitle && (
              <div className={styles["form-group"]}>
                <div className={styles.toHide}>
                  <input
                    type="checkbox"
                    name="nda"
                    defaultChecked={nda}
                    id="html"
                  />
                </div>

                <img
                  src={nda ? IconChecked : IconNonChecked}
                  alt="checkbox"
                  onClick={() => setNda(!nda)}
                  className={styles.marginLeftStyle}
                />

                <label htmlFor="html" onClick={() => setNda(!nda)}>
                  <span className={styles.ndaStyle}>
                    {contactSectionFields[6]?.label}
                  </span>

                  <img
                    src={InfoIcon}
                    alt="redkite info icon"
                    className={styles["info-icon"]}
                    title="non-disclosure agreement"
                  />
                </label>
                <br></br>
              </div>
            )}

            <div className={cx({ [styles.attacheMargin]: !isJobTitle })}>
              <label
                htmlFor={styles["file"]}
                className={styles["attached-file"]}
              >
                <div id={styles["hide"]}>
                  <input
                    type="file"
                    id="contactUsFileUpload"
                    onChange={attachFile}
                    multiple
                    accept=".pdf,.doc,.docx,.xls,.xlsx,image/*"
                    name="files[]"
                  />
                </div>
                <label
                  htmlFor="contactUsFileUpload"
                  className={styles["attached-file-text"]}
                >
                  <span className={styles.attachStyle}>
                    {contactSectionFields[7]?.label}
                  </span>
                </label>
              </label>

              <div className={styles.filesContainer}>
                {files.map((file, fileIndex) => (
                  <p key={file.name} className={styles.fileDisplayWrapper}>
                    <span className={styles.fileName}>{file.name}</span>
                    <span className={styles.removeFile}>
                      <img
                        src={CloseIcon}
                        alt="close icon"
                        width="30px"
                        onClick={() => {
                          let newFiles = [...files];
                          newFiles.splice(fileIndex, 1);
                          setFiles(newFiles);
                          document.getElementById("contactUsFileUpload").value =
                            "";
                        }}
                      />
                    </span>
                  </p>
                ))}
                <div className={styles.fileErrorContainer}>{fileError}</div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.formActionButtons}>
          <button
            className={cx(styles["ecommerce-contact-us-form-button"], isJobTitle ? 'job_application_submit' : 'contact_submit_button')}
            disabled={formLoading}
          >
            {contactSectionButton}
          </button>
          {formLoading && <LoadingCircle />}
          {formLoading && <div className={styles.loadingBg} />}
          <input type="reset" hidden id="resetContactUsForm" />
        </div>
        {afterSendSuccess && (
          <div className={styles.afterSendSuccess}>{afterSendSuccess}</div>
        )}
        {afterSendError && (
          <div className={styles.afterSendError}>{afterSendError}</div>
        )}
      </form>
    </div>
  );
};

export default ContactUs;
