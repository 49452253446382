import React, { useState } from "react";
import arrowDown from "../../img/arrowDown.svg";
import MainHeaderItem from "./MainHeaderItem";
import MainHeaderCallButton from "./MainHeaderCallButton";
import styles from "./MainHeader.module.scss";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

function MainHeaderItemsBox({
  navigation,
  callNumber,
  lang,
  l,
  callBackText,
  callBackLink,
  close,
  setClose,
  locale,
}) {
  const history = useHistory();
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);

    const path = history.location.pathname.replace(
      /^\/([a-z|A-Z]{2})/,
      `/${language}`
    );

    history.replace(path);
  };

  const [show, setShow] = useState(false);

  const handleClickShow = () => {
    setShow((prev) => !prev);
  };

  return (
    <div className={styles["main-header-items-box-box"]}>
      {!!navigation &&
        navigation.map((works) => {
          return (
            <MainHeaderItem
              key={works.itemLink}
              {...works}
              l={l}
              lang={lang}
              locale={locale}
            />
          );
        })}

      <div className={show ? styles.wr : styles.wrN}>
        <div onClick={handleClickShow}>
          {locale === "ru" ? "Рус" : "Eng"}
          <img
            src={arrowDown}
            alt="redkite languages"
            className={styles.arrowDown}
          />
        </div>

        <div className={show ? styles.classShow : styles.classHide}>
          {locale === "ru" ? (
            <button
              onClick={() => {
                changeLanguage("en");
                setShow(false);
              }}
              className={styles.buttonLang}
            >
              Eng
            </button>
          ) : null}
          {locale === "en" ? (
            <button
              onClick={() => {
                changeLanguage("ru");
                setShow(false);
              }}
              className={styles.buttonLang}
            >
              Рус
            </button>
          ) : null}
        </div>
      </div>
      <MainHeaderCallButton
        callNumber={callNumber}
        lang={lang}
        callBackText={callBackText}
        callBackLink={callBackLink}
        setClose={setClose}
        close={close}
        locale={locale}
      />
    </div>
  );
}
export default MainHeaderItemsBox;
