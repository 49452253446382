import React from "react";
import styles from "./Blocks.module.scss";
import cx from "classnames";
import BlocksItems from "./BlocksItems";
const Blocks = ({ newStyle, titleSectionAdditionalFields, secondColumn }) => {
  return (
    <div className={cx(newStyle)}>
      {!!titleSectionAdditionalFields &&
        titleSectionAdditionalFields.map((data) => {
          return (
            <BlocksItems
              key={data.firstColumn}
              {...data}
              newStyle={styles.block}
            />
          );
        })}
    </div>
  );
};

export default Blocks;
