import React from "react";
import styles from "./LargIMGWithRightBox.module.scss";
function LargIMGWithRightBoxImageBox({ image }) {
  return (
    <div>
      <img
        src={image}
        alt="Redkite section img"
        className={styles["ecommerce-second-box-img"]}
      />
    </div>
  );
}

export default LargIMGWithRightBoxImageBox;
