import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styles from "./MainFooter.module.scss";
import MainFooterSocialIcons from "./MainFooterSocialIcons";
import LangContext from "../../LangContext";
function MainFooterFirstContact({
  socialMedia,
  socialMediaTitle,
  footerLogo,
  l,
  termsText,
  policyText,
  policyLink,
  termsLink,
}) {
  const handleScroll = () => {
    window.scrollTo(0, 0);
  };
  const locale = useContext(LangContext);
  return (
    <div>
      <div className={styles.upWrapper}>
        <div className={styles["main-footer-first-text-connect"]}>
          {socialMediaTitle}
        </div>
        <div className={styles.up}>
          <a href="#top">
            <div className={styles.goToTopIcon}></div>
          </a>
        </div>
      </div>
      <div>
        <div className={styles["contact-box"]}>
          {!!socialMedia &&
            socialMedia.map((data) => {
              return <MainFooterSocialIcons key={data.image} {...data} />;
            })}
          <Link to={`/${locale}`}>
            <img
              src={footerLogo}
              alt="RedKite Website Logo"
              className={styles["main-footer-redkite-logo-img"]}
              onClick={handleScroll}
            />
          </Link>
        </div>

        <div className={styles.t_p}>
          <div className={styles.p}>
            <a
              href={`/${locale}${policyLink}`}
              className={
                locale === "en" ? styles.linkColor : styles.linkColorRUS2
              }
              dangerouslySetInnerHTML={{
                __html: policyText,
              }}
            />
          </div>

          <div className={styles.t}>
            <a
              href={`/${locale}${termsLink}`}
              className={
                locale === "en" ? styles.linkColor : styles.linkColorRUS3
              }
              dangerouslySetInnerHTML={{
                __html: termsText,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default MainFooterFirstContact;
