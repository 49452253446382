import React from "react";
import styles from "./JobSmallImg.module.scss";
import Text from "../../common/Text/Text";
import img from "../../../img/Employee.svg";

function JobSmallImg({ image, description, text }) {
  return (
    <div className={styles.boxWrapper}>
      <div>
        <img src={image} className={styles.img} alt="job" />
      </div>
      <div className={styles.textBox}>
        <Text weight="700" textStyle={styles.title}>
          {description}
        </Text>
        <Text weight="500">{text}</Text>
      </div>
    </div>
  );
}

export default JobSmallImg;
