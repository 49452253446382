import React from "react";
import styles from "./MainFooter.module.scss";
function MainFooterFirstTouch({ getInTouchText, getInTouchTitle }) {
  return (
    <div className={styles["main-footer-first-touch-box"]}>
      <div className={styles["main-footer-first-text"]}>{getInTouchTitle}</div>
      <div
        className={styles.mainFooterFontSize}
        dangerouslySetInnerHTML={{
          __html: getInTouchText,
        }}
      />
    </div>
  );
}
export default MainFooterFirstTouch;
