import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import styles from "./WorkSection.module.scss";
import "./arrow.scss";
import LangContext from "../../../LangContext";
function WorksSection({ image, heading, workLink, l }) {
  const locale = useContext(LangContext);
  return (
    <div>
      <Link to={`${locale}/${workLink}`} style={{ textDecoration: "none" }}>
        <div className={styles.imgWrapper}>
          <img src={image} alt="redkite works" className={styles["work-img"]} />
        </div>
        <p
          className={styles["work-img-title"]}
          dangerouslySetInnerHTML={{ __html: heading }}
        />
      </Link>
    </div>
  );
}
export default WorksSection;
