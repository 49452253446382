import React from "react";
import styles from "./AboutSection.module.scss";
import cx from "classnames";
function AboutSectionFirstRowItems({ image, imgAlt, text, firstRowTextStyle }) {
  return (
    <div className={styles["about-section-first-row-items-box"]}>
      <div className={styles["about-section-first-row-items-box-box"]}>
        <img
          src={image}
          alt={imgAlt}
          className={styles["about-section-pics"]}
        />
      </div>
      <div className={styles["about-section-first-row-items-text-box"]}>
        <div
          className={cx(
            styles["about-section-first-row-items-text"],
            firstRowTextStyle
          )}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </div>
  );
}
export default AboutSectionFirstRowItems;
