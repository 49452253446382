import React from "react";

import styles from "./VideoSection.module.scss";

function VideoSectionPartners({ partners, partnerIcons }) {
  return (
    <div className={styles["first-section-partners-box1"]}>
      <div className={styles["first-section-partners-box"]}>
        <div className={styles["first-section-partners-text"]}>{partners}</div>
        <div className={styles["first-section-partners"]} id="clients">
          {!!partnerIcons &&
            partnerIcons.map((icon) => {
              return (
                <img
                  src={icon}
                  className={styles["first-section-partners-img"]}
                  alt="we partner with"
                  key={icon}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}
export default VideoSectionPartners;
