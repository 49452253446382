import React from "react";
import IMGBox from "./IMGBox";
import BoxBox from "./BoxBox";

import styles from "./IMGWithRightBox.module.scss";
import { useMediaQuery } from "react-responsive";

function IMGWithRightBox({ heading, paragraph, leftImage, rightImage }) {
  const mystyle = {
    backgroundImage: `url(${leftImage})`,
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  const mystyle1 = {
    backgroundImage: "none",
  };
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  return (
    <div
      className={styles["ecommerce-second-box-with-IMG-and-box-box"]}
      style={isMobile ? mystyle1 : mystyle}
    >
      <div className={styles["ecommerce-why-redkite-right-section"]}>
        {isMobile && <IMGBox leftImage={leftImage} />}
        <BoxBox
          heading={heading}
          paragraph={paragraph}
          rightImage={rightImage}
        />
      </div>
    </div>
  );
}
export default IMGWithRightBox;
