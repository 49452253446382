import React, { useState } from "react";
import { Switch, Route, Redirect, useParams } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ErrorBoundary as ErrBoundary } from "react-error-boundary";
import MainHeader from "./components/MainHeader/MainHeader";
import ErrorWraaper from "./components/common/ErrorWraaper";
import MainPage from "./components/MainPage/MainPage";
import InternalPageLayout from "./components/InternalPageLayout/InternalPageLayout";
import MainFooter from "./components/MainFooter/MainFooter";
import NotFound from "./components/common/NotFound";
import CallBackForm from "./components/common/CallBackForm";
import PopUp from "./components/common/PopUp/PopUp";
import Success from "./img/success.svg";
import Error from "./img/error.svg";
import AboutStaff from "./components/AbouStaff/AboutStaff";
import Career from "./components/Career/Career";
import Job from "./components/JobSection/Job";
import Terms from "./components/Terms/Terms";
import Privacy from "./components/Privacy/Privacy";
import LangContext from "./LangContext";
import LinkContext from "./LinkContext";
import { useTranslation } from "react-i18next";

const defErrMsg = "is not responding. Please try refreshing the page";
const menuErrMsg = `Menu ${defErrMsg}`;
const footErrMsg = `Footer ${defErrMsg}`;

function App() {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const { locale } = params;
  const lng = locale || i18n.language;

  const [close, setClose] = useState(true);

  const [afterSendSuccess, setAfterSendSuccess] = useState();
  const [afterSendError, setAfterSendError] = useState();

  return (
    <div className="App">
      <Provider store={store}>
        <LangContext.Provider value={lng}>
          <ErrBoundary
            fallbackRender={() => <ErrorWraaper message={menuErrMsg} />}
          >
            <MainHeader setClose={setClose} close={close} locale={lng} />

            <CallBackForm
              close={close}
              setClose={setClose}
              setAfterSendSuccess={setAfterSendSuccess}
              setAfterSendError={setAfterSendError}
            />
            {afterSendSuccess && (
              <PopUp img={Success} text={t("success.callBack")} />
            )}
            {afterSendError && (
              <PopUp img={Error} text={t("warning.callBack")} />
            )}
          </ErrBoundary>

          <ErrBoundary fallbackRender={() => <ErrorWraaper type="main" />}>
            <Switch>
              <Route path={`/:${lng}/404`} component={NotFound} />

              <Route exact path={`/:${lng}`}>
                <MainPage setClose={setClose} close={close} locale={lng} />
              </Route>

              <Route exact path={`/:${lng}/about`} component={AboutStaff} />
              <Route exact path={`/:${lng}/career`} component={Career} />

              <Route
                exact
                path={`/:${lng}/job-postings/:jobs`}
                component={(props) => (
                  <LinkContext.Provider value={props.match.params.jobs}>
                    <Job {...props} jobs={props.match.params.jobs} />
                  </LinkContext.Provider>
                )}
              />

              <Route
                exact
                path={`/:${lng}/tos/privacy-policy`}
                component={Privacy}
              />

              <Route
                exact
                path={`/:${lng}/tos/terms-of-use`}
                component={Terms}
              />

              <Route
                exact
                path={`/:${lng}/:pageName/:posting?`}
                component={(props) => (
                  <InternalPageLayout
                    {...props}
                    pageName={props.match.params.pageName}
                    posting={props.match.params.posting}
                    locale={lng}
                  />
                )}
              />

              <Route exact path="/">
                <Redirect to={`/${i18n.language}`} />
              </Route>

              <Route path="*" component={NotFound}>
                <Redirect to={`/:${lng}/404`} />
              </Route>
            </Switch>
          </ErrBoundary>

          <ErrBoundary
            fallbackRender={() => <ErrorWraaper message={footErrMsg} />}
          >
            <MainFooter />
          </ErrBoundary>
        </LangContext.Provider>
      </Provider>
    </div>
  );
}
export default App;
