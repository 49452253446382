import React, { useContext } from "react";
import styles from "./VideoSection.module.scss";
import IconPlay from "../../../img/IconPlay.svg";
import IconStop from "../../../img/IconStop.svg";
import cx from "classnames";
import { HashLink as Link } from "react-router-hash-link";
import LangContext from "../../../LangContext";
function VideoSectionTextInfo({
  headline,
  headlineButton,
  headlineLink,
  videoRef,
  isPlaying,
  isMuted,
  setIsPlaying,
  setIsMuted,
  headlineParagraph,
  l,
}) {
  const locale = useContext(LangContext);
  return (
    <div
      className={cx(styles["first-section-text-info-box"], {
        [styles.activfirstsectiontextinfobox]: l === "Eng",
      })}
    >
      <div>
        <div className={styles["first-section-text"]}>{headline}</div>
        <div
          className={cx(styles["first-section-second-text"], {
            [styles.ENFirstSectionSecondText]: l === "Eng",
          })}
          dangerouslySetInnerHTML={{ __html: headlineParagraph }}
        />

        {!!headlineButton && (
          <div
            className={cx(styles["first-section-button-box"], {
              [styles.ENFirstSectionButtonBox]: l === "Eng",
            })}
          >
            <Link to={`/${locale}${headlineLink}`}>
              <input
                type="button"
                value={headlineButton}
                className={styles["first-section-button"]}
              />
            </Link>
          </div>
        )}
      </div>
      <div className={styles["first-section-play-button-box"]}>
        {isPlaying ? (
          <img
            src={IconStop}
            alt="Stop"
            className={styles["first-section-play-button"]}
            onClick={() => {
              // eslint-disable-next-line no-unused-expressions
              videoRef?.current?.pause();
              setIsPlaying(false);
            }}
          />
        ) : (
          <img
            src={IconPlay}
            alt="play"
            className={styles["first-section-play-button"]}
            onClick={() => {
              // eslint-disable-next-line no-unused-expressions
              videoRef?.current?.play();
              setIsPlaying(true);
            }}
          />
        )}
      </div>
    </div>
  );
}
export default VideoSectionTextInfo;
