import React, { useEffect, useState, useRef } from "react";
import VideoSectionTextInfo from "./VideoSectionTextInfo";
import VideoSectionPartners from "./VideoSectionPartners";
import styles from "./VideoSection.module.scss";
function VideoSection({
  headline,
  video,
  headlineButton,
  headlineLink,
  partners,
  partnerIcons,
  headlineParagraph,
  l,
}) {
  const [videoUrl, setVideoUrl] = useState(video);
  const [isPlaying, setIsPlaying] = useState(true);
  const videoRef = useRef();
  useEffect(() => {
    setVideoUrl(video);
  }, [video]);

  return (
    <div className={styles["first-section"]}>
      <div className={styles["bg-gradient"]}></div>
      <div
        className={`${styles["bg-video"]} ${styles["bg-video1"]}`}
        id="bg-video"
      >
        {/*  TO DO */}
        {/* {videoUrl && (
          <video
            ref={videoRef}
            className={styles.videoOption}
            autoPlay="true"
            loop
            muted
            preload
            id="videoOption"
          >
            <source src={videoUrl} type="video/mp4"></source>
          </video>
        )} */}
      </div>

      <VideoSectionTextInfo
        headline={headline}
        headlineButton={headlineButton}
        headlineLink={headlineLink}
        videoRef={videoRef}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        headlineParagraph={headlineParagraph}
        l={l}
      />
      <VideoSectionPartners partners={partners} partnerIcons={partnerIcons} />
    </div>
  );
}
export default VideoSection;
