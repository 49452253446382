import React, { useEffect, useContext } from "react";
import styles from "./Job.module.scss";
import { useMediaQuery } from "react-responsive";
import CareerImgComponent from "../common/CareerImgComponent/CareerImgComponent";
import CareerImgComponentForMobile from "../common/CareerImgComponent/CareerImgComponentForMobile/CareerImgComponentForMobile";
import CareerBoxforItem from "../Career/CareerBoxforItem/CareerBoxforItem";
import Title from "../common/Text/Title";
import JobSmallImg from "./JobSmallImg/JobSmallImg";
import JobDescription from "./JobDescription/JobDescription";
import JobApply from "./JobApply/JobApply";
import SocialMedia from "./SocialMedia/SocialMedia";
import AboutUs from "../common/AboutSection";
import useFetchPageData from "../../utils/useFetchPageData";
import LangContext from "../../LangContext";

const Job = ({ jobs }) => {
  const l = useContext(LangContext);

  const jobData = useFetchPageData(`/job-postings/${jobs}`, true);
  const aboutData = useFetchPageData("aboutus", true);
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!jobData.jobPostingContent) {
    return null;
  }

  return (
    <div>
      <div>
        <CareerImgComponent
          backgroundStyle={styles.backgroundStyle}
          bgPicture={jobData.jobPostingImage}
        >
          {isMobile && <CareerImgComponentForMobile />}
          <CareerBoxforItem boxStyle={styles.boxStyle}>
            <Title titleStyle={styles.textStyle}>{jobData.title}</Title>
            <div className={styles.rowWrapper}>
              {jobData.jobPostingContent.basicInfo.map((data) => {
                return <JobSmallImg key={data.image} {...data} />;
              })}
            </div>

            {jobData.jobPostingContent.jobDetails.map((data) => {
              return <JobDescription key={data.heading} {...data} />;
            })}

            <JobApply jobTitle={jobData.title} />
            <SocialMedia
              socialMediaTitle={jobData.socialMediaTitle}
              socialMedia={jobData.socialMedia}
            />
          </CareerBoxforItem>
        </CareerImgComponent>
      </div>
      <AboutUs
        heading={aboutData.aboutHeading}
        big={aboutData.aboutBig}
        small={aboutData.aboutSmall}
        l={l}
        aboutStyle={styles.aboutStyle}
        stylesAboutId={styles.stylesAboutId}
        firstRowTextStyle={styles.firstRowTextStyle}
        newStyle={styles.newStyle}
      />
    </div>
  );
};

export default Job;
