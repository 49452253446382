import React from "react";
import styles from "./FeaturesList.module.scss";
import FeaturesListItems from "./FeaturesListItems";
function FeaturesListItemsBox({ data }) {
  return (
    <div className={styles["ecommerce-page-redkite-will-items-box"]}>
      {!!data &&
        data.map((data) => {
          return <FeaturesListItems key={data.image} {...data} />;
        })}
    </div>
  );
}
export default FeaturesListItemsBox;
