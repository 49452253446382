import React, { useContext } from "react";
import styles from "./AboutSection.module.scss";
import LangContext from "../../../LangContext";
import { useTranslation } from "react-i18next";
function AboutSectionButton() {
  const locale = useContext(LangContext);
  const { t } = useTranslation();
  return (
    <div className={styles.buttonBox} id="contact">
      <a href={`/${locale}/about`}>
        <input
          type="button"
          value={t("button.buttonText")}
          className={styles.buttonContent}
        />
      </a>
    </div>
  );
}

export default AboutSectionButton;
