import React from "react";
import LargIMGWithRightBoxRightBox from "./LargIMGWithRightBoxRightBox";
import LargIMGWithRightBoxImageBox from "./LargIMGWithRightBoxImageBox";
import styles from "./LargIMGWithRightBox.module.scss";
import { useMediaQuery } from "react-responsive";
function LargIMGWithRightBox({
  heading,
  paragraph,
  image,
  button,
  buttonLink,
  showContent,
}) {
  const mystyle = {
    backgroundImage: `url(${image})`,
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  const mystyle1 = {
    backgroundImage: "none",
  };
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });
  return (
    !!showContent && (
      <div
        className={styles["ecommerce-why-redkite-box"]}
        style={isMobile ? mystyle1 : mystyle}
      >
        <div className={styles["ecommerce-why-redkite-right-section"]}>
          {isMobile && <LargIMGWithRightBoxImageBox image={image} />}
          <LargIMGWithRightBoxRightBox
            heading={heading}
            paragraph={paragraph}
            button={button}
            buttonLink={buttonLink}
          />
        </div>
      </div>
    )
  );
}
export default LargIMGWithRightBox;
