import React from "react";

import CoverBoxText from "./CoverBoxText";
import BoxAndText from "../RightTextAndLeftBoxWithButtons/BoxAndText";
import styles from "./Cover.module.scss";
function CoverBox({
  heading,
  paragraph,
  buttonLink,
  buttonText,
  image,
  text,
  titleSectionAdditionalFields,
  showContent,
}) {
  return (
    !!showContent && (
      <div>
        <div className={styles.coverBox}>
          <div className={styles.coverBoxForText}>
            <CoverBoxText
              heading={heading}
              paragraph={paragraph}
              buttonLink={buttonLink}
              buttonText={buttonText}
              image={image}
            />
          </div>
        </div>
        {!!text &&
          text.map((data) => {
            return (
              <BoxAndText
                key={data.firstButtonLink}
                {...data}
                titleSectionAdditionalFields={titleSectionAdditionalFields}
              />
            );
          })}
      </div>
    )
  );
}
export default CoverBox;
