import React from "react";
import Text from "./Text";
import StaffBox from "./StaffBox";
import styles from "./Staff.module.scss";
function Staff({ title, subTitle, staffHeading, staffProps }) {
  return (
    <div className={styles.mainBox}>
      <Text title={title} subTitle={subTitle} />
      <StaffBox staffHeading={staffHeading} staffProps={staffProps} />
    </div>
  );
}

export default Staff;
