import React, { useContext } from "react";
import styles from "./LeftAndRightSections.module.scss";
import { Link } from "react-router-dom";
import LangContext from "../../../LangContext";
function LeftAndRightSectionsButton({ button, buttonLink, l }) {
  const locale = useContext(LangContext);

  return (
    <div className={styles["ecommerce-success-stories-sub-box-button-box"]}>
      <Link to={`/${locale}/${buttonLink}/`}>
        <input
          type="button"
          value={button}
          className={styles["ecommerce-success-stories-sub-box-button"]}
        />
      </Link>
    </div>
  );
}
export default LeftAndRightSectionsButton;
