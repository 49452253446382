import React, { useState } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import arrowDown from "../../../img/arrowDown.svg";
import MobileMenuItems from "./MobileMenuItems";
import MainHeaderCallButton from "../../MainHeader/MainHeaderCallButton";
import styles from "./MobileMenu.module.scss";
import { useTranslation } from "react-i18next";
import LogoWhite from "../../../img/LogoWhite.svg";
import LogoBlack from "../../../img/LogoBlack.svg";
import { isPathMatching } from "../../../utils/isPathMatching";
function MobileMenuItemsBox({
  navigation,
  showMobileMenu,
  lang,
  l,
  callNumber,
  callBackText,
  callBackLink,
  setClose,
  close,
  locale,
}) {
  const history = useHistory();
  const { i18n } = useTranslation();
  const { pathname } = useLocation({});

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);

    const path = history.location.pathname.replace(
      /^\/([a-z|A-Z]{2})/,
      `/${language}`
    );

    history.replace(path);
  };
  const [show, setShow] = useState(false);
  const handleClickShow = () => {
    setShow((prev) => !prev);
  };

  return (
    <div className={styles["main-header-items-box-box"]}>
      <div>
        <MainHeaderCallButton
          callNumber={callNumber}
          lang={lang}
          callBackText={callBackText}
          callBackLink={callBackLink}
          setClose={setClose}
          close={close}
          locale={locale}
        />
        <div className={styles.menuItemWrapper}>
          {!!navigation &&
            navigation.map((works) => {
              return (
                <MobileMenuItems
                  key={works.itemLink}
                  {...works}
                  showMobileMenu={showMobileMenu}
                  l={l}
                  lang={lang}
                />
              );
            })}
        </div>

        <div className={show ? styles.wr : styles.wrN}>
          <div>
            {locale === "ru" ? "Рус" : "Eng"}
            <img
              src={arrowDown}
              alt="redkite languages"
              className={styles.arrowDown}
              onClick={handleClickShow}
            />
          </div>

          <div className={show ? styles.classShow : styles.classHide}>
            {locale === "ru" ? (
              <button
                onClick={() => {
                  changeLanguage("en");
                  setShow(false);
                }}
                className={
                  pathname === `/${locale}`
                    ? styles.buttonLang
                    : styles.buttonLangBlack
                }
              >
                Eng
              </button>
            ) : null}
            {locale === "en" ? (
              <button
                onClick={() => {
                  changeLanguage("ru");
                  setShow(false);
                }}
                className={
                  pathname === `/${locale}`
                    ? styles.buttonLang
                    : styles.buttonLangBlack
                }
              >
                Рус
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <Link exact to={`/${locale}`}>
        <div className={styles.bottomLogo}>
          <img
            src={isPathMatching(useLocation(), locale) ? LogoWhite : LogoBlack}
            alt="Redkite Logo"
          />
        </div>
      </Link>
    </div>
  );
}
export default MobileMenuItemsBox;
