import React from "react";
import styles from "./Blocks.module.scss";
import cx from "classnames";

const BlocksItems = ({ newStyle, firstColumn, secondColumn }) => {
  return (
    <div className={cx(styles.blocksBox, newStyle)}>
      <div
        dangerouslySetInnerHTML={{
          __html: firstColumn,
        }}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: secondColumn,
        }}
      />
    </div>
  );
};

export default BlocksItems;
