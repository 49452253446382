import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { initializeGTM } from './GTMSetup';
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "react-app-polyfill/ie11";

import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";

initializeGTM();

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Router>
      <Route path="/:locale([a-z|A-Z]{2})?" component={App} />
    </Router>
  </I18nextProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
