import React from "react";
import AboutSectionBoxSecondRowItems from "./AboutSectionBoxSecondRowItems";
import cx from "classnames";
import styles from "./AboutSection.module.scss";

function AboutSectionBoxSecondRow({ small, l, newStyle, forAbout }) {
  return (
    <div
      className={cx(styles["about-section-box-second-row-box"], forAbout, {
        [styles.ENAboutSectionBoxSecondRowBox]: l === "en",
      })}
    >
      {!!small &&
        small.map((data) => {
          return (
            <AboutSectionBoxSecondRowItems
              key={data.heading}
              {...data}
              l={l}
              newStyle={newStyle}
            />
          );
        })}
    </div>
  );
}
export default AboutSectionBoxSecondRow;
