import React, { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import MainFooterFirst from "./MainFooterFirst";
import MainFooterSecond from "./MaintFooterSecond";
import styles from "./MainFooter.module.scss";
import LangContext from "../../LangContext";
import { setSocialData } from "../../redux/actions/commonActions";

function MainFooter() {
  const distpatch = useDispatch();
  const [apiData, setApiData] = useState({});
  const l = useContext(LangContext);
  
  async function fetchData() {
    const res = await fetch(
      `${process.env.REACT_APP_API}footer.json?lang=${l}`
    );
    res.json().then((res) => {
      distpatch(setSocialData(res));
      setApiData(res);
    });
  }

  useEffect(() => {
    fetchData();
  }, [l]);

  return (
    <div>
      <div className={styles.mainFooterBox}>
        <MainFooterFirst
          subscribeTitle={apiData.subscribeTitle}
          getInTouchTitle={apiData.getInTouchTitle}
          socialMediaTitle={apiData.socialMediaTitle}
          getInTouchText={apiData.getInTouchText}
          socialMedia={apiData.socialMedia}
          footerLogo={apiData.footerLogo}
          subscribePlaceholder={apiData.subscribePlaceholder}
          subscribeButton={apiData.subscribeButton}
          l={l}
          termsText={apiData.termsText}
          policyText={apiData.policyText}
          copyright={apiData.copyright}
          policyLink={apiData.policyLink}
          termsLink={apiData.termsLink}
        />
        <MainFooterSecond
          copyright={apiData.copyright}
          policyText={apiData.policyText}
          termsText={apiData.termsText}
          policyLink={apiData.policyLink}
          termsLink={apiData.termsLink}
          l={l}
        />
      </div>
    </div>
  );
}
export default MainFooter;
