import React from "react";
import IMGWithRightBox from "./IMGWithRightBox";
import FeaturesList from "../FeaturesList/FeaturesList";
import Blocks from "../Blocks";
import styles from "./IMGWithRightBox.module.scss";

function MainBoxForIMGAndBox({
  mainContent,
  heading,
  data,
  contentSectionAdditionalFields,
  testimoniesSectionAdditionalFields,
  showContent,
}) {
  return (
    !!showContent && (
      <div>
        {!!mainContent &&
          mainContent.map((data) => {
            return <IMGWithRightBox key={data.heading} {...data} />;
          })}

        {!!contentSectionAdditionalFields && (
          <Blocks
            titleSectionAdditionalFields={contentSectionAdditionalFields}
            newStyle={
              data ? styles.blockFirst : styles.blockFirstWithoutFeatureList
            }
          />
        )}
        {!!data && <FeaturesList heading={heading} data={data} />}

        {!!testimoniesSectionAdditionalFields && (
          <Blocks
            titleSectionAdditionalFields={testimoniesSectionAdditionalFields}
            newStyle={styles.blockLast}
          />
        )}
      </div>
    )
  );
}
export default MainBoxForIMGAndBox;
