import React from "react";
import styles from "./MainHeader.module.scss";
import { useLocation } from "react-router-dom";
function MainHeaderCallButton({ callNumber, callBackText, setClose, locale }) {
  function handleClose() {
    setClose(false);
  }
  return (
    <div className={styles["mainHeaderCallButtonBox"]}>
      
      <div className={styles["mainHeaderCallBack"]} onClick={handleClose}>
        <div>
          {useLocation().pathname === `/${locale}` ? (
            <div className={styles.whiteButton}>
              <button>{callBackText}</button>
            </div>
          ) : (
            <div className={styles.blackButton}>
              <button>{callBackText}</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default MainHeaderCallButton;
