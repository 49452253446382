import React, { useState, useEffect } from "react";
import Background from "./Background/Background";
import Item from "./Item/Item";
import Spinner from "./Spinner/Spinner";
import styles from "./Solutions.module.scss";

const Solutions = ({ heading, text, solutions, button, l }) => {
  const [activeItem, setActiveItem] = useState(null);
  const [backgroundsArr, setBackgroundsArr] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const deactivator = () => {
    setActiveItem(null);
    setBackgroundsArr([]);
  };

  const activator = (index) => {
    if (
      (index || index === 0) &&
      backgroundsArr[backgroundsArr?.length - 1] !== solutions[index]?.imageBig
    ) {
      setActiveItem(index);
      setBackgroundsArr([...backgroundsArr, solutions[index]?.imageBig]);
    }
  };

  const cacheImages = (srcArray) => {
    let counter = 1;
    const total = srcArray.length;
    srcArray.forEach((src) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        counter++;
        if (counter === total) setIsLoading(false);
      };
    });
  };

  useEffect(() => {
    const smallImages = solutions.map((element) => element?.image);
    const bigImages = solutions.map((element) => element?.imageBig);
    cacheImages([...smallImages, ...bigImages]);
  }, []);

  return (
    <section className={styles.solutions}>
      <div className={styles.heading}>{heading}</div>
      <div className={styles.text} dangerouslySetInnerHTML={{ __html: text }} />

      {isLoading ? (
        <Spinner />
      ) : (
        <div className={styles.grid} onMouseLeave={deactivator}>
          <Background backgroundsArr={backgroundsArr} />

          {solutions.map((element, index) => (
            <Item
              description={element.description}
              heading={element.heading}
              image={element.image}
              solutionLink={element.solutionLink}
              sectionActive={activeItem || activeItem === 0}
              onMouseOver={() => activator(index)}
              active={index === activeItem}
              key={index}
              l={l}
            />
          ))}
        </div>
      )}
    </section>
  );
};
export default Solutions;
