import React, { useState } from "react";
import axios from "axios";
import styles from "./CallBackForm.module.scss";
import Viber from "../../../img/viberForForm.svg";
import WhatsApp from "../../../img/whatsappForForm.svg";
import ViberChecked from "../../../img/viberForFormChecked.svg";
import WhatsAppChecked from "../../../img/whatsappForFormChacked.svg";

import Loading from "../../common/LoadingCircle";

function Fields({
  contactSectionFields,
  termsText,
  send,
  setAfterSendSuccess,
  setAfterSendError,
  handleClose,
  toEmail,
}) {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [iconViber, setIconViber] = useState(false);
  const [iconWhatsApp, setIconWhatsApp] = useState(false);
  const [viber, setViber] = useState(Viber);
  const [whatsApp, setWhatsApp] = useState(WhatsApp);

  function handleClickViber() {
    setIconViber(!iconViber);
    iconViber ? setViber(Viber) : setViber(ViberChecked);
  }

  function handleClickWhatsApp() {
    setIconWhatsApp(!iconWhatsApp);
    iconWhatsApp ? setWhatsApp(WhatsApp) : setWhatsApp(WhatsAppChecked);
  }

  const [formLoading, setFormLoading] = useState(false);

  if (!contactSectionFields) {
    return null;
  }

  const submit = (e) => {
    e.preventDefault();
    setFormLoading(true);

    const formData = new FormData();
    formData.set("action", "contact-form/send");
    formData.set("subject", "Call Back");
    formData.set("fromEmail", "information@redkite.io");
    formData.set("message[Name]", name);
    formData.set("toEmail", toEmail);
    formData.append("message[Contact Number]", number);
    if (iconViber) {
      formData.append("message[Platform]", "viber");
    } else if (iconWhatsApp) {
      formData.append("message[Platform]", "whatsapp");
    }

    axios({
      url: process.env.REACT_APP_API,
      data: formData,
      method: "POST",
    })
      .then(() => setAfterSendSuccess(true))
      .catch(() => setAfterSendError(true))
      .finally(() => setFormLoading(false))
      .finally(() => setName(""))
      .finally(() => setNumber(""))
      .finally(() => setIconViber())
      .finally(() => setIconWhatsApp())
      .finally(() => handleClose());
  };

  return (
    <div className={styles.inputFormBox}>
      <form onSubmit={submit}>
        <div>
          <div className={styles.labelName}>
            {contactSectionFields[0].label} <span>*</span>
          </div>
          <input
            type="text"
            maxLength={35}
            className={styles.inputProps}
            pattern="^[a-zA-Z ]+$"
            title="Enter valid name"
            value={name}
            required
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </div>
        <div>
          <div className={styles.labelName}>
            {contactSectionFields[1].label} <span>*</span>
          </div>
          <input
            type="text"
            maxLength={20}
            className={styles.inputProps}
            pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
            title="Enter valid number"
            required
            value={number}
            onChange={(e) => {
              setNumber(e.target.value);
            }}
          />
        </div>
        <div className={styles.iconsBox}>
          <div>
            <label>{termsText}</label>
          </div>
          <img
            src={viber}
            className={styles.iconSize}
            onClick={handleClickViber}
            alt="social icon"
          />
          <img
            src={whatsApp}
            className={styles.iconSize}
            onClick={handleClickWhatsApp}
            alt="social icon"
          />
        </div>
        <div className={styles.submitBox}>
          <button type="submit" className={styles.submitButton}>
            {send}
          </button>
        </div>
      </form>
      {formLoading && <Loading />}
      {formLoading && <div className={styles.loadingBg} />}
    </div>
  );
}

export default Fields;
