import React from "react";
import styles from "./Cover.module.scss";
import { NavHashLink } from "react-router-hash-link";
function CoverBoxText({ heading, paragraph, buttonLink, buttonText, image }) {
  const mystyle = {
    backgroundImage: `url(${image})`,
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  return (
    <div
      className={styles["ecommerce-page-cover-box-text-box"]}
      style={mystyle}
    >
      {!!heading && (
        <div
          className={styles["ecommerce-page-cover-box-text-title"]}
          dangerouslySetInnerHTML={{
            __html: heading,
          }}
        />
      )}

      {!!paragraph && (
        <div
          className={styles["ecommerce-page-cover-box-text-subtitle"]}
          dangerouslySetInnerHTML={{
            __html: paragraph,
          }}
        />
      )}
      
      {!!buttonLink && (    
        <div className={styles["ecommerce-page-header-buttons-box"]}>    
          <NavHashLink to={buttonLink}>
            <button
              className={styles["ecommerce-page-header-buttons"]}
              dangerouslySetInnerHTML={{
                __html: buttonText,
              }}
            />
          </NavHashLink>
        </div>
      )}
    </div>
  );
}
export default CoverBoxText;
