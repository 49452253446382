import React from "react";
import styles from "./JobDescription.module.scss";
import Text from "../../common/Text/Text";
function JobDescription({ heading, paragraph }) {
  return (
    <div className={styles.TextWrapperBox}>
      <Text weight="700" textStyle={styles.title}>
        {heading}
      </Text>
      <Text weight="500" textStyle={styles.subTitle}>
        {paragraph}
      </Text>
    </div>
  );
}

export default JobDescription;
