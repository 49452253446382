import React from "react";
import FB from "../../../img/facebookInTouch.svg";
import Instagram from "../../../img/instagramInTouch.svg";
import LinkedIn from "../../../img/LinkedinInTouch.svg";
import styles from "./GetInTouchSection.module.scss";
function ContactSocialIcons() {
  return (
    <div className={styles.socialMediaWrapper}>
      <div>
        <a href="https://www.facebook.com/redkiteyerevan" target="_blank">
          <img src={FB} alt="Facebook icon" className={styles.perIcon} />
        </a>
        <a href="https://www.instagram.com/redkiteyerevan/" target="_blank">
          <img
            src={Instagram}
            alt="Instagram icon"
            className={styles.perIcon}
          />
        </a>
        <a href="https://www.linkedin.com/company/redkiteam/" target="_blank">
          <img src={LinkedIn} alt="LinkedIn icon" className={styles.perIcon} />
        </a>
      </div>
    </div>
  );
}

export default ContactSocialIcons;
