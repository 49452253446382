import React from "react";
import styles from "./IMGWithRightBox.module.scss";

function BoxBox({ heading, paragraph, rightImage }) {
  return (
    <div
      className={styles["ecommerce-second-box-box"]}
      style={{ backgroundImage: `url(${rightImage})` }}
    >
      <div
        className={styles["ecommerce-second-box-title"]}
        dangerouslySetInnerHTML={{ __html: heading }}
      />
      <div
        className={styles["ecommerce-second-box-text"]}
        dangerouslySetInnerHTML={{ __html: paragraph }}
      />
    </div>
  );
}
export default BoxBox;
