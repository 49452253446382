import React from "react";
import LargIMGWithRightSubBoxText from "./LargIMGWithRightSubBoxText";
import LargIMGWithRightSubBoxButton from "./LargIMGWithRightSubBoxButton";
import styles from "./LargIMGWithRightBox.module.scss";
function LargIMGWithRightSubBox({ heading, paragraph, button, buttonLink }) {
  return (
    <div className={styles["ecommerce-why-redkite-right-subbox-box"]}>
      <LargIMGWithRightSubBoxText heading={heading} paragraph={paragraph} />
      <LargIMGWithRightSubBoxButton button={button} buttonLink={buttonLink} />
    </div>
  );
}
export default LargIMGWithRightSubBox;
