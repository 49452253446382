import React from "react";
import styles from "./CareerImgComponentForMobile.module.scss";
import CareerBg from "../../../../img/CareerBg.png";
function CareerImgComponentForMobile({}) {
  return (
    <div>
      <img src={CareerBg} alt="Redkite Career" className={styles.imgStyle} />
    </div>
  );
}

export default CareerImgComponentForMobile;
