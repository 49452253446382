import React, { useContext } from "react";
import styles from "./MainFooter.module.scss";
import cx from "classnames";
import { Link } from "react-router-dom";
import LangContext from "../../LangContext";
const date = new Date();
const year = date.getFullYear();
function MainFooterSecond({
  copyright,
  policyText,
  termsText,
  policyLink,
  termsLink,
  l,
}) {
  const locale = useContext(LangContext);

  return (
    <div
      className={cx(styles.footer, {
        [styles.Engfooter]: l === "Eng",
      })}
    >
      <div className={styles.copyright}>
        © 2014 - {year} {copyright}
      </div>

      <div className={styles.privacy}>
        <Link exact to={`${locale}${policyLink}`}>
          <div
            className={cx(l === "en" ? styles.linkColor : styles.linkColorRUS)}
            dangerouslySetInnerHTML={{ __html: policyText }}
          />
        </Link>
      </div>

      <div className={styles.terms}>
        <Link exact to={`${locale}${termsLink}`}>
          <div
            className={styles.linkColor}
            dangerouslySetInnerHTML={{ __html: termsText }}
          />
        </Link>
      </div>
    </div>
  );
}
export default MainFooterSecond;
