import React, { useState, useEffect } from "react";
import MainHeaderLogo from "./MainHeaderLogo";
import MainHeaderItemsBox from "./MainHeaderItemsBox";
import { useLocation } from "react-router-dom";
import useWindowScrollPosition from "@rehooks/window-scroll-position";
import Icon_Menu_white from "../../img/IconMenu.svg";
import Icon_Menu_Black from "../../img/Icon_Menu_Black.svg";
import styles from "./MainHeader.module.scss";
import MobileMenu from "../common/MobileMenu";
import { removeMainAnimation } from "../../utils/loading";

const scrollBreakPoint = 10;
const headerBgClass = "transparent";
const WhiteHeaderBgClass = "transparentWhite";

function MainHeader({ close, setClose, locale }) {
  const [apiData, setApiData] = useState({});

  async function fetchData() {
    const res = await fetch(
      `${process.env.REACT_APP_API}menu.json?lang=${locale}`
    );
    res.json().then((res) => {
      setApiData(res);
      removeMainAnimation();
    });
  }

  useEffect(() => {
    fetchData();
  }, [locale]);

  const { pathname } = useLocation({});
  const headerClass =
    pathname === `/${locale}` ? "homeHeader" : "internalHeader";
  const [bgClass, setBgClass] = useState(headerBgClass);
  const [WhiteBgClass, setWhiteBgClass] = useState(WhiteHeaderBgClass);
  const [mobileMenu, showMobileMenu] = useState(false);

  let position = useWindowScrollPosition();

  useEffect(() => {
    if (pathname === `/${locale}`) {
      setBgClass(position.y > scrollBreakPoint ? "" : styles[headerBgClass]);
    } else {
      setBgClass("");
    }

    if (pathname !== `/${locale}`) {
      setWhiteBgClass(
        position.y > scrollBreakPoint ? "" : styles[WhiteHeaderBgClass]
      );
    } else {
      setWhiteBgClass("");
    }
  }, [pathname, position.y, locale]);

  return (
    <div
      className={`mainHeaderBox ${styles[headerClass]} ${bgClass} ${WhiteBgClass}`}
    >
      <MainHeaderLogo
        logoWhite={apiData.logoWhite}
        logoBlack={apiData.logoBlack}
        locale={locale}
      />

      <MainHeaderItemsBox
        navigation={apiData.navigation}
        callNumber={apiData.callNumber}
        callBackText={apiData.callBackText}
        callBackLink={apiData.callBackLink}
        setClose={setClose}
        close={close}
        locale={locale}
      />

      <div className={styles.asas}>
        <img
          src={
            useLocation().pathname === `/${locale}`
              ? Icon_Menu_white
              : Icon_Menu_Black
          }
          alt="redkite menu icon for mobile"
          className={styles.burgerMenu}
          onClick={() => {
            showMobileMenu(!mobileMenu);
          }}
        />
      </div>
      <MobileMenu
        show={mobileMenu}
        showMobileMenu={showMobileMenu}
        navigation={apiData.navigation}
        callNumber={apiData.callNumber}
        callBackText={apiData.callBackText}
        callBackLink={apiData.callBackLink}
        setClose={setClose}
        close={close}
      />
    </div>
  );
}
export default MainHeader;
