import React from "react";
import styles from "./SocialMedia.module.scss";
import "./shareButton.scss";
import Facebook from "../../../img/FacebookJobIcon.svg";
import Copy from "../../../img/IconCopy.svg";
import LinkedIn from "../../../img/LinkedIn.svg";
import Text from "../../common/Text/Text";
import { FacebookShareButton, LinkedinShareButton } from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";

function SocialMedia({ socialMediaTitle, socialMedia }) {
  const copyLinkHandler = () => {
    const currentLink = window.location.href;
    navigator.clipboard.writeText(currentLink);
  };
  const url = window.location.href;
  return (
    <div>
      <Text weight="700" textStyle={styles.title}>
        {socialMediaTitle}
      </Text>
      <div>
        <FacebookShareButton url={socialMedia[0].socialMediaLink}>
          <img
            src={Facebook}
            alt="Share job icon"
            className={styles.shareImage}
          />
        </FacebookShareButton>

        <LinkedinShareButton url={socialMedia[1].socialMediaLink}>
          <img
            src={LinkedIn}
            alt="Share job icon"
            className={styles.shareImage}
          />
        </LinkedinShareButton>
        <CopyToClipboard text={url}>
          <img
            src={Copy}
            alt="Copy job link"
            className={styles.shareImage}
            onClick={copyLinkHandler}
          />
        </CopyToClipboard>
      </div>
    </div>
  );
}

export default SocialMedia;
