import React, { useState } from "react";
import styles from "./PopUp.module.scss";
import CloseForm from "../../../img/CloseForm.svg";
import cx from "classnames";

function PopUp({ newStyle, img, text, handleCloseAction }) {
  const [show, setShow] = useState(true);
  function handleClick() {
    setShow(false);

    if (handleCloseAction) {
      handleCloseAction();
    }
  }

  return (
    <div className={show ? styles.PopUpBox : styles.PopUpBoxHide}>
      <div className={cx(styles.PopUpBoxText, newStyle)}>
        <div className={styles.closeBox}>
          <img
            src={CloseForm}
            className={styles.closeBoxIcon}
            onClick={handleClick}
            alt="close icon"
          />
        </div>
        <img src={img} alt="Success" className={styles.icon} />
        <div>{text}</div>
      </div>
    </div>
  );
}

export default PopUp;
