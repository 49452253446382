import React, { useContext } from "react";
import cx from "classnames";
import { Link } from "react-router-dom";
import styles from "./Career.module.scss";
import Text from "../../common/Text/Text";
import LangContext from "../../../LangContext";

function CareerItem({ itemBoxStyle, link, date, heading, content, jobs }) {
  const locale = useContext(LangContext);
  jobs = link;
  if (!content) {
    return null;
  }

  return (
    <Link exact to={`/${locale}/${jobs}`} target="_blank">
      <div className={cx(styles.mainBox, itemBoxStyle)}>
        <div className={styles.JobBox}>
          <Text weight="700" textStyle={styles.title}>
            {heading}
          </Text>
          <Text weight="500" textStyle={styles.time}>
            {date}
          </Text>
        </div>
        <div className={styles.descriptionBox}>
          <div className={styles.descriptionRow}>
            <Text weight="700" textStyle={styles.description}>
              {`${content[0].description}:`}
            </Text>
            <Text weight="500" textStyle={styles.descriptionText}>
              {`${content[0].text}`}
            </Text>
          </div>
          <div className={styles.descriptionRow}>
            <Text weight="700" textStyle={styles.description}>
              {`${content[1].description}:`}
            </Text>
            <Text weight="500" textStyle={styles.descriptionText}>
              {`${content[1].text}`}
            </Text>
          </div>
          <div className={styles.descriptionRow}>
            <Text weight="700" textStyle={styles.description}>
              {`${content[2].description}:`}
            </Text>
            <Text weight="500" textStyle={styles.descriptionText}>
              {`${content[2].text}`}
            </Text>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default CareerItem;
