import React from "react";
import styles from "./AboutSection.module.scss";
import cx from "classnames";
function AboutSectionBoxSecondRowItems({
  image,
  imgAlt,
  paragraph,
  heading,
  l,
  newStyle,
  newStyle1,
}) {
  return (
    <div className={styles["about-section-box-second-row-item-box"]}>
      <div className={styles["about-section-second-row-items-box"]}>
        <div>
          <img
            src={image}
            alt={imgAlt}
            className={styles["about-section-second-row-pics"]}
          />
        </div>
        <div
          className={
            styles["about-section-text-row-items-title-and-paragraph-box"]
          }
        >
          <div
            className={cx(
              styles["about-section-text-row-items-title"],
              newStyle
            )}
            dangerouslySetInnerHTML={{ __html: heading }}
          />

          <div
            className={cx(
              styles["about-section-text-row-items-text"],
              newStyle,
              {
                [styles.ENAboutSectionTextRowItemsText]: l === "en",
              }
            )}
            dangerouslySetInnerHTML={{ __html: paragraph }}
          />
        </div>
      </div>
    </div>
  );
}
export default AboutSectionBoxSecondRowItems;
