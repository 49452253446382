import React from "react";
import GradientNumberListItems from "./GradientNumberListItems";
import styles from "./GradientNumbersList.module.scss";
function GradientNumberListItemsBox({ data, roadmapSectionData }) {
  return (
    <div className={styles["ecommerce-customer-journey-items-box-box"]}>
      {!!data &&
        data.map((data) => {
          return <GradientNumberListItems key={data.number} {...data} />;
        })}
    </div>
  );
}
export default GradientNumberListItemsBox;
