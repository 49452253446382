import React, { useContext } from "react";
import styles from "./JobApply.module.scss";
import { NavHashLink } from "react-router-hash-link";
import PageLoading from "../../common/Loading";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import LangContext from "../../../LangContext";

function JobApply({ jobTitle }) {
  const locale = useContext(LangContext);
  const { t } = useTranslation();

  let jobPostingButton = t("jobPostingButton");

  return (
    <div>
      <PageLoading />

      <div className={styles.boxWrapper}>
        <NavHashLink to={`/${locale}/start-now/${jobTitle}`}>
          <div className={styles.submitBox}>
            <label htmlFor="contactUsFileUpload">
              <button className={cx(styles.submitButton)}>
                {jobPostingButton}
              </button>
            </label>
          </div>
        </NavHashLink>
      </div>
    </div>
  );
}

export default JobApply;
