import { useState, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setPageData, setLoading } from "../redux/actions/commonActions";
import LangContext from "../LangContext";
import { removeMainAnimation } from "../utils/loading";

const setPageMeta = (res) => {
  document.title =
    res?.title && res.title !== "undefined" ? res.title : "RedKite";
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", res.description);
  document
    .querySelector('meta[name="keywords"]')
    .setAttribute("content", res.keywords);
};

const useFetchPageData = (pageName, updateMeta) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [apiData, setApiData] = useState({});

  const l = useContext(LangContext);

  const contentFetcher = async (l, pageName) => {
    dispatch(setLoading(true));
    await fetch(`${process.env.REACT_APP_API}${pageName}.json?lang=${l}`)
      .then((res) => {
        if (!res.ok) {
          history.push(`/${l}/404`);
        }

        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        if (updateMeta) {
          setPageMeta(res);
        }
        setApiData(res);
        dispatch(setPageData(pageName, JSON.parse(JSON.stringify(res))));
      })
      .catch(() => {
        history.push(`/${l}/404`);
      })
      .finally(() => {
        removeMainAnimation();
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    contentFetcher(l, pageName);
  }, [l, pageName]);

  return apiData;
};

export default useFetchPageData;
